
:root {
  --brandColor: #146E6E;
  --brandSecColor: #FF7800;
  --mainPadding: 0;
  --contentWidth: 1520px;
  --elementMainHeight: 46px;
  --contentTopBottomPadding: 60px 0;
}

$mainPadding: var(--mainPadding);
$contentWidth: var(--contentWidth);
$elementMainHeight: var(--elementMainHeight);
$contentTopBottomPadding: var(--contentTopBottomPadding);

//--------Colors---------
$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$greyColor: #F7F9FD;
$greyBgColor: #F2F3F5;
$greyBorderColor: #E6E8EA;
$darkTextColor: #8D8B95;
$blackTextColor: #333;
$greyTextColor: #767E8F;
$borderColor: #e0e0e0;
$redColor: #FE353A;


@media (max-width: 1700px) {
  :root {
    --contentWidth: 1400px;
  }
}
@media (max-width: 1500px) {
  :root {
    --contentWidth: 1200px;
  }
}

@media (max-width: 1260px) {
  :root {
    --contentWidth: 1060px;
    --elementMainHeight: 40px;
  }
}
@media (max-width: 1160px) {
  :root {
    --contentWidth: 940px;
  }
}
@media (max-width: 1000px) {
  :root {
    --contentWidth: unset;
    --mainPadding: 4%;
  }
}
@media (max-width: 900px) {
  :root {
    --contentTopBottomPadding: 20px 0;
  }
}
@media (max-width: 600px) {
  :root {
    --mainPadding: 12px;
  }
}
