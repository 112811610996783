@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.no-result-empty-view {
  width: 100%;
  height: 100%;
  margin: 100px 0;
  @include flex-center;
  flex-direction: column;

  img {
    width: 400px;
    height: 162px;
  }

  p {
    margin: 20px 0;
    color: black;
    @include font(Medium, 16);
  }

  .link-button {
    width: 160px;
    height: 40px;
    font-size: 13px;
    letter-spacing: 0;
  }

  @media all and (max-width: 500px) {
    margin: 50px 0;
    img {
      width: 70%;
      height: unset;
    }
    p {
      font-size: 14px;
    }
  }
}
