@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mixins";

.image-uploader {
  overflow: hidden;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  width: 160px;
  height: 160px;
  background-color: white;

  .image-wrapper {
    width: 100%;
    height: 100%;
    background-blend-mode: lighten;
    position: relative;

    .image-wrapper-hover {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .2);
      transition: all 0.2s linear;
      visibility: hidden;
      opacity: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .img-selector {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all .2s linear;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    //border-style: solid;
    //border-width: 4px 4px 4px 4px;
    //-moz-border-image: url('../../../images/icons/ic_border.svg') 5 5 5 5 repeat repeat;
    //-webkit-border-image: url('../../../images/icons/ic_border.svg') 5 5 5 5 repeat repeat;
    //-o-border-image: url('../../../images/icons/ic_border.svg') 5 5 5 5 repeat repeat;
    //border-image: url('../../../images/icons/ic_border.svg') 5 5 5 5 repeat repeat;

    //-moz-border-image: url('../../../images/icons/ic_border_hover.svg') 5 5 5 5 repeat repeat;
    //-webkit-border-image: url('../../../images/icons/ic_border_hover.svg') 5 5 5 5 repeat repeat;
    //-o-border-image: url('../../../images/icons/ic_border_hover.svg') 5 5 5 5 repeat repeat;
    //border-image: url('../../../images/icons/ic_border_hover.svg') 5 5 5 5 repeat repeat;
    background-size: 100% 100%;
    opacity: 0;
    color: white;
    font-size: 18px;

    img {
      width: 25px;
    }

    span {
      margin-top: 8px;
      font-weight: 600;
      @include font(Regular, 13);
    }

    &:hover {
      //-moz-border-image: url('../../../images/icons/ic_border_hover.svg') 5 5 5 5 repeat repeat;
      //-webkit-border-image: url('../../../images/icons/ic_border_hover.svg') 5 5 5 5 repeat repeat;
      //-o-border-image: url('../../../images/icons/ic_border_hover.svg') 5 5 5 5 repeat repeat;
      //border-image: url('../../../images/icons/ic_border_hover.svg') 5 5 5 5 repeat repeat;
      opacity: 1;
    }
  }

  &:hover {
    .image-wrapper .image-wrapper-hover{
      visibility: visible;
      opacity: 1;
    }
  }

  input {
    display: none;
  }
}
