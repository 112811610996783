@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.notifications-wrapper {
  .notifications-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(47, 51, 56, 0.16), inset 0 0 0 15px #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 15px 40px;
    height: 80px;


    .notifications-title {
      color: black;
      font-family: Medium;
      font-size: 15px;
    }

    .notifications-switch-wrapper {
      color: black;

      .ant-switch {
        margin-left: 20px;
        min-width: 38px;
      }

      .ant-switch-checked {
        background-color: $brandColor;
      }
    }
  }

  .notifications-empty-view {
    width: 100%;
    height: 100%;
    padding: 139px 0;
    @include flex-center;
    flex-direction: column;

    img {
      width: 200px;
    }

    p {
      margin: 20px 0;
      color: black;
      @include font(Medium, 16);
    }
  }



  .notifications {
    min-height: 644px;

    .notification-item-card {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $greyBorderColor;
      padding: 15px 20px 15px 40px;
      transition: .2s;
      position: relative;

      &.link-notification {
        cursor: pointer;
      }

      .left-part {
        display: flex;
        align-items: center;
        width: 90%;
        height: 100%;

        .image-wrapper {
          height: 50px;
          width: 50px;
          background-color: $brandColor;
          margin-right: 20px;
          border-radius: 10px;
          @include flex-center;

          svg {
            width: 29px;
            height: 26px;

            path {
              fill: white;
            }
          }
        }

        .notification-content {
          width: 100%;

          .title {
            width: 100%;
            height: 26px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include font(Medium, 16)
          }

          .description-wrapper {

            .description {
              line-height: 22px;
              margin-top: 4px;
              @include font(Regular, 13);
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;


              &.show-all {
                height: unset;
                overflow: unset;
                white-space: unset;
              }

            }

            .see-more {
              color: $brandColor;
              @include font(Regular, 13);
            }
          }
        }
      }

      .right-part {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .delete-content {
          visibility: hidden;
          opacity: 0;
          cursor: pointer;
          transition: .2s;

          polygon {
            transition: .2s;
          }

          &:hover {
            svg {
              polygon {
                fill: #FF3232;
              }
            }
          }
        }
      }

      .notification-date {
        color: $greyTextColor;
        font-size: 13px;
      }

      &:hover {
        .right-part {
          .delete-content {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }


  @media all and (max-width: 1150px) {
    .notifications {
      .notification-item-card {
        padding: 15px 20px;
      }
    }
  }
  @media all and (max-width: 1000px) {
    .notifications {
      .notification-item-card {
        .right-part {
          .delete-content {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  @media all and (max-width: 750px) {

    .notifications {
      .notification-item-card {
        padding: 10px;
      }
    }

    .notifications-empty-view {
      margin: 50px 0;

      p {
        font-size: 14px;
      }
    }
  }

  @media all and (max-width: 500px) {
    .notifications-content-header {
      .notifications-title {
        font-size: 14px;
      }

      .notifications-switch-wrapper {
        font-size: 12px;

        .ant-switch {
          margin-left: 5px;
        }

      }
    }

    .notifications {
      flex-direction: column;

      .notification-item-card {
        flex-direction: column;
        align-items: unset;
        justify-content: center;

        .left-part {
          width: 100%;
        }

        .right-part {
          .delete-content {
            position: absolute;
            top: 12px;
            right: 12px;
          }
        }

        .notification-date {
          margin: 0;
          text-align: right;
        }
      }
    }
  }
}
