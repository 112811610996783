@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.apps-page-wrapper {
  width: 100%;

  .apps-info-section {
    width: $contentWidth;
    margin: 80px auto 140px auto;
    display: flex;
    justify-content: space-between;
    border-top: 0.5px solid white;

    .image-wrapper {
      width: 50%;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .apps-info-content {
      width: 44%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .apps-info-title {
        font-size: 36px;
        line-height: 50px;
        font-family: Medium;
        margin-bottom: 30px;
      }

      .apps-info-description {
        font-size: 18px;
        font-family: Regular;
        color: black;
        overflow: hidden;
        margin-bottom: 60px;
      }

      .PrimaryButton {
        width: 160px;
        margin-top: 30px;
      }
    }

    &.second {
      .image-wrapper {
        .image-proportion {
          padding-top: 73.7%;
        }
      }
    }
  }

  @media all and (max-width: 1000px) {
    margin-top: 96px;

    .apps-info-section {
      flex-direction: column;
      padding: $mainPadding;
      margin: 20px auto;

      .image-wrapper {
        width: 100%;
        margin-bottom: 50px;
      }

      .apps-info-content {
        width: 100%;
      }

      &.second {
        flex-direction: column-reverse;
      }
    }
  }

  @media all and (max-width: 700px) {

    .apps-info-section {
      flex-direction: column;
      padding: $mainPadding 0;

      .image-wrapper {
        width: 100%;
        margin-bottom: 30px;
      }

      .apps-info-content {
        width: 100%;

        .apps-info-title {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 20px;
        }

        .apps-info-description {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }
  }
}