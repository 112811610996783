@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.markets-wrapper {

  .markets-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 20px 0;

    .title {
      font-size: 28px;
      font-family: Medium;
      color: black;
      letter-spacing: 1px;
    }

    .view-more {
      height: 35px;
      border-radius: 2px;
      @include flex-center;
      @include font(Medium, 17);
      color: $brandSecColor;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $brandColor;
      }
    }
  }

  .rout {
    display: flex;
    flex-wrap: wrap;

    .rout-item {
      margin-right: 12px;
      margin-bottom: 12px;

      a {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #B1B1B3;
        transition: .3s;
        &:hover {
          color: $brandColor;
        }
      }

      svg {
        margin-left: 10px;
        width: 5px;
        path {
          fill: #797b7f;
        }
      }
    }
  }

  .markets-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .markets-item {
      width: calc(50% - 8px);
      border-radius: 6px;

      &:nth-child(n+3) {
        margin-top: 16px;
      }

      .image-wrapper {
        width: 100%;
        padding-top: 40.9%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 6px;
          object-fit: cover;
        }

        .inner-side {
          position: absolute;
          top: 0;
          z-index: 1;
          color: #222;
          width: 60%;
          height: 100%;
          padding: 4%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 36px;
            font-family: Medium;
            margin-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .description {
            font-size: 16px;
            overflow: hidden;
            height: 72px;
            margin: 12px 0 20px;
          }

          .markets-btn {
            color: #3D3D3D;
            border-radius: 2px;
            width: 150px;
            height: 40px;
            @include font(Medium, 13);
            display: flex;
            align-items: center;
            text-transform: uppercase;
            transition: .2s;

            svg {
              path, polygon {
                transition: .2s;
              }
              transform: rotate(180deg);
              margin-left: 8px;
              margin-top: -2px;
            }
          }
        }

        &:hover {
          .markets-btn {
            color: $brandSecColor;
          }
          svg {
            path, polygon {
              fill: $brandSecColor;
              //stroke: $brandSecColor;
            }
          }
        }

        @media all and (max-width: 600px) {
          //padding-top: 40%;
          img {
            border-radius: 2px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1500px) {
    .markets-content {

      .markets-item {
        .image-wrapper {
          .inner-side {
            .title {
              font-size: 30px;
              margin-bottom: 0;
            }

            .description {
              font-size: 13px;
              height: 60px;
              margin-bottom: 20px;
            }

            .markets-btn {
              width: 140px;
              height: 32px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1200px) {
    .markets-content {

      .markets-item {
        .image-wrapper {
          .inner-side {
            width: 72%;
            .title {
              font-size: 24px;
              margin-bottom: 0;
            }

            .description {
              font-size: 13px;
              height: 50px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .markets-content {
      flex-direction: column;

      .markets-item {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }


  @media all and (max-width: 600px) {
    .markets-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 20px;

      .title {
        font-size: 20px;
      }

      .view-more {
        width: 70px;
        height: 26px;
        font-size: 12px;
      }

    }
  }

  @media all and (max-width: 450px) {
    .markets-content {

      .markets-item {
        .image-wrapper {
          .inner-side {
            //width: 45%;

            .title {
              font-size: 16px;
              margin-bottom: 0;
            }

            .description {
              font-size: 12px;
              height: 38px;
              margin-bottom: 6px;
            }
          }
        }
      }
    }
  }
}