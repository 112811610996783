@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.orders-wrapper {
  min-height: 644px;

  .orders-content-header {
    padding: 15px 40px;
    margin-bottom: 10px;
    color: black;
    font-family: Medium;
    font-size: 20px;
    height: 80px;
    display: flex;
    align-items: center;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .orders-tabs {
    display: flex;
    min-height: 644px;

    .order-card {
      &:first-child {
        .order-card-wrapper {
          border-top-color: transparent;
        }
      }

      &:last-child {
        .order-card-wrapper {
          border-bottom: 1px solid $borderColor;
        }

        &.active-order {
          .order-card-wrapper {
            border-bottom: 1px solid #F5F9F9;
          }
        }
      }

      &.active-order {
        background-color: #F5F9F9;
        width: calc(100% + 20px);
        padding-right: 20px;

        .order-card-wrapper {
          border-top-color: #F5F9F9;
        }

        + .order-card {

          > .order-card-wrapper {
            border-top-color: #F5F9F9;

          }
        }
      }
    }

    .ant-tabs {
      width: 100%;

      .ant-tabs-tabpane {
        width: unset;
        flex-grow: 1;
      }
    }

    .order-details {
      background-color: #F5F9F9;
      width: 350px;
      padding: 12px 20px 30px;

      .order-details-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .details-title {
          @include font(Medium, 20)
        }

        svg {
          position: relative;
          right: -9px;
          cursor: pointer;

          polygon {
            transition: .2s;
          }

          &:hover {
            polygon {
              fill: $redColor;
            }
          }
        }
      }

      .details-products-list {
        max-height: 262px;
        overflow-y: auto;
        @include custom-scrollbar-y;

        .product-item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $borderColor;
          padding: 8px 0;

          .image-wrapper {
            width: 70px;
            height: 100%;
            display: flex;
            align-items: baseline;
            border-radius: 6px 0 0 6px;
            margin-right: 12px;

            .image-proportion {
              padding-top: 100%;
              position: relative;
              width: 100%;

              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 6px 0 0 6px;
              }
            }
          }

          .product-content {
            width: calc(100% - 82px);


            .product-title {
              font-size: 15px;
              line-height: 22px;
              font-family: Medium;
              max-height: 45px;
              min-height: 28px;
              overflow: hidden;
              color: black;
            }

            .price-content {
              display: flex;
              align-items: center;
              font-size: 15px;
              font-family: Bold;
              letter-spacing: .6px;
              color: $brandColor;
            }

            .delete-btn {
              cursor: pointer;
              display: flex;
              align-items: center;

              svg {
                polygon {
                  transition: .2s;
                }
              }

              &:hover {
                svg {
                  polygon {
                    fill: $redColor;
                  }
                }
              }
            }
          }
        }
      }

      .products-count-wrapper {
        display: flex;
        justify-content: flex-end;
        color: $brandSecColor;
        padding: 12px 0;
      }

      .order-info-wrapper {
        padding: 20px 12px 12px;
        background-color: white;
        border-radius: 4px;

        .order-info-item {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          margin-bottom: 10px;

          .info-item-title {
            font-family: Medium;
          }

          .info-item-text {
            max-width: 200px;
            text-align: right;
          }

          &.price-item {
            margin-top: 24px;

            .info-item-title {
              font-family: Medium;
              font-size: 15px;
            }

            .info-item-text {
              font-family: Bold;
              font-size: 18px;
            }
          }
        }
      }

      .btns-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .PrimaryButton {
          transition: unset;
          height: 36px;
          width: calc(60% - 4px);
          font-size: 13px;

          &:disabled {
            background: #d3d3d3 !important;
          }


          &.cancel-btn:not(:disabled) {
            background-color: transparent;
            border: 1px solid $brandColor;
            color: $brandColor;
          }

          &.delete-btn {
            background-color: $redColor;
            width: calc(40% - 4px);

            &:first-child {
              width: 100%;
            }
            &:hover {
              background-color: #C9232E;
            }
          }
        }
      }
    }
  }

  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    padding-left: 20px;
    margin: 0;

    &::before {
      border-bottom-color: $borderColor;
    }
  }

  .ant-tabs-tab {
    color: #202020;
    padding: 12px;
    font-family: Medium;

    + .ant-tabs-tab {
      margin: 0 0 0 12px;
    }

    &:hover {
      color: $brandColor !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: $brandColor !important;
    }
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: $brandColor;
    height: 3.5px;
  }


  .orders-empty-view {
    width: 100%;
    height: 100%;
    padding: 139px 0;
    @include flex-center;
    flex-direction: column;

    img {
      width: 200px;
    }

    p {
      margin: 20px 0;
      color: black;
      @include font(Medium, 16);
    }
  }

  @media all and (max-width: 1200px) {
    .ant-tabs-content {
      flex-direction: column;
      height: unset;
    }

    .orders-tabs {
      .hide-list {
        display: none;
      }


      .order-details {
        width: 100%;
      }
    }
  }

  @media all and (max-width: 1150px) {
    .orders-content-header {
      padding: 15px 20px;
    }
  }

  @media all and (max-width: 1000px) {
    .orders-content-header, .ant-tabs-content-holder {
      padding: 10px;
    }
    .orders-content-header {
      height: 65px;
    }
    .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
      padding-left: 10px;
    }
  }

  @media all and (max-width: 750px) {

    .orders-empty-view {
      margin: 50px 0;

      p {
        font-size: 16px;
      }
    }
  }
  @media all and (max-width: 850px) {
    .orders-tabs {
      .ant-tabs {
        .ant-tabs-content-holder {
          padding: 0;
        }
      }
    }
  }
}