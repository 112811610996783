@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.favorites-wrapper {
  overflow: hidden;

  .favorites-content-header {
    padding: 15px 40px;
    color: black;
    font-family: Medium;
    font-size: 20px;
    height: 80px;
    display: flex;
    align-items: center;
  }

  .favorite-blocked-products {

    .product-image-proportion {
      .product-img {
        width: 120px;
        height: 120px;
        object-fit: contain;
      }
    }

    .right-side {
      width: calc(100% - 130px);
    }

    .product-card {
        bottom: -1px;
    }
  }

  .favorite-empty-view {
    width: 100%;
    height: 100%;
    padding: 139px 0;
    @include flex-center;
    flex-direction: column;

    img {
      width: 200px;
    }

    p {
      margin: 20px 0;
      color: black;
      @include font(Medium, 16);
    }
  }

  @media all and (max-width: 1550px) {
    .favorite-blocked-products {
      .product-card-wrapper {
        width: calc(100% / 3);
      }
    }
  }

  @media all and (max-width: 1150px) {
    .favorites-content-header {
      padding: 15px 20px;
    }
  }

  @media all and (max-width: 1000px) {
    .favorites-content-header {
      padding: 10px;
    }

    .favorite-blocked-products {

      .custom-input-group, .password-wrapper, .link-button, .birth-day-wrapper {
        width: 100%;
      }

      .custom-input-group {
        margin-bottom: 5px;
      }

      .custom-select {
        margin-bottom: 8px;
      }

      .password-wrapper {
        margin-bottom: 15px;
      }
    }
  }

  @media all and (max-width: 750px) {
    .favorites-content-header {
      padding: 10px;
    }

    .favorite-empty-view {
      margin: 50px 0;

      p {
        font-size: 14px;
      }
    }
  }

  @media all and (max-width: 600px) {
    .favorite-blocked-products {
      .product-card-wrapper {
        width: calc(100% / 2);
      }
    }
  }
}