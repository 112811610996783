@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.settings-wrapper {
  width: 100%;
  padding: 20px 40px 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .uploader-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .fields-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left-wrapper, .right-wrapper {
      width: calc(50% - 8px);

      .custom-input-group, .password-wrapper {
        width: 100%;
      }

      .PrimaryButton {
        width: 100%;
      }
    }

    .right-wrapper {
      .email-wrapper {
        display: flex;
        justify-content: space-between;

        .custom-input-group {
          width: calc(100% - 12px - #{$elementMainHeight});
        }

        .PrimaryButton {
          width: $elementMainHeight;
          padding: 0;
        }
      }
    }
  }

  .custom-input-group {
    width: 48%;

    label {
      color: black;
    }
  }

  .ant-select {
    &.invalid {
      .ant-select-selector {
        border-color: red !important;
      }
    }
    .ant-select-selector {

      .ant-select-selection-search {
        display: flex;
        align-items: center;
      }
    }

  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: white !important;
  }

  .password-wrapper {
    width: 48%;

    .hidden-email-wrapper {
      height: 0;
      width: 0;

      .custom-input-group {
        margin-bottom: 0;
      }
    }

    .custom-input-group, .changePassword {
      width: 100%;
    }
  }

  .PrimaryButton {
    width: 48%;
    background-color: $brandColor;
  }

  .birth-day-wrapper {
    width: 48%;

    label {
      width: 100%;
      margin-bottom: 8px;
      display: block;
      font-size: 13px;
      font-weight: 500;
      color: black;
    }

    .inputs-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      .custom-input-group {
        width: 33%;

        &:first-child {
          width: 28%;
        }
      }
    }
  }
  @media all and (max-width: 1150px) {
    padding: 20px 20px;
  }
  @media all and (max-width: 1000px) {

    .fields-wrapper {
      flex-direction: column;

      .left-wrapper, .right-wrapper {
        width: 100% !important;
      }
    }

    .custom-input-group {
      margin-bottom: 10px;
    }

    .password-wrapper {
      margin-bottom: 10px;
    }

    .PrimaryButton {
      width: 100%;
    }
  }
}