@import "../../assets/styles/fonts";
@import "../../assets/styles/mixins";
@import "../../assets/styles/mainVariables";

.footerWrapper {
  background-color: #F2F3F5;

  .top-side {
    padding: 30px $mainPadding;
    justify-content: space-between;
    width: $contentWidth;
    margin: 0 auto;

    .col {

      &.large-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .logo-wrapper{
        //margin-bottom: 40px;
        img {
          height: 50px;
          margin-bottom: 44px;
        }

        p {
          margin-bottom: 8px;
        }
      }
      .social-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #B9BCCF;
        padding-bottom: 12px;

        .social-title {
          @include font(Medium, 14)
        }

        .social-content {
          width: 100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;

          a {
            display: flex;
            align-items: center;
          }

          img {
            max-width: 24px;
            max-height: 24px;
            object-fit: contain;
            cursor: pointer;
          }
        }
      }

      .footer-middle-section {
        .section-title {
          @include font(Medium, 14)
        }
        .section-description {
          color: $greyTextColor;
          font-size: 13px;
        }
      }

      .contact-wrapper {
        display: flex;

        .custom-input-group {
          flex-grow: 1;
          margin-bottom: 0;
          input {
            border-radius: 0;
            margin-bottom: 0;
          }
        }
        .PrimaryButton {
          border-radius: 0;
          padding: 0 16px;
          font-size: 13px;
          .anticon-loading{
            margin-left: 10px;
            svg {
              path, polygon{
                fill: white;
              }
            }
          }
          .tick-icon{
            height: 24px;
            width: 24px;
            margin-left: 10px;
            transition: all 0.2s;
            visibility: visible;
            path, polygon{
              fill: white;
            }
          }
          .show-tick{
            visibility: visible;
            opacity: 1;
          }
          .hide-tick{
            visibility: hidden;
            opacity: 0;
            width: 0;
            margin-left: 0;
          }
        }
      }

      .title {
        font-family: Bold;
        margin: 4px 0 16px 0;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      a p {
        transition: .2s;

        &:hover {
          color: $brandColor;
        }
      }

      p {
        color: $greyTextColor;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include font(Medium, 13);
        margin-bottom: 4px;
      }
    }

    &.mobile {
      display: none;
    }

    &.desktop {
      display: flex;
    }

    @media all and (max-width: 1000px) {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
      }
    }
  }

  .bottom-side {
    border-top: 1px solid #B9BCCF;
    padding: 20px var(--mainPadding);

    .bottom-side-main-content {
      width: $contentWidth;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $greyTextColor;
    }

    .developed-by {
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px;
      }
    }
  }


  @media all and (max-width: 1200px) {
    .top-side {
      .col {
        &:last-child, &:first-child {
          width: unset;
        }

        .title {
          font-size: 16px;
        }
      }
    }

  }

  @media all and (max-width: 1000px) {
    .top-side {
      flex-wrap: wrap;
      padding: 10px 0 30px;

      .logo-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;

        img {
          height: 40px;
          padding-left: 16px;
          margin: 20px 0;
        }
      }

      .social-wrapper {
        .social-content {
          margin-top: 8px;
          width: 80px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;

          svg {
            cursor: pointer;
          }
        }
      }

      .top-side-bottom-wrapper {
        position: relative;
        padding: 12px 22px 12px 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .ant-collapse {
        width: 100%;
        background: inherit;
        border: 0;

        .ant-collapse-item {

          .ant-collapse-header {

            .ant-collapse-arrow {
              top: 10px;
              padding-top: 0;
              transition: all 0.4s ease;


              &.rotate {
                transform: rotate(-180deg) !important;
              }
            }
          }

          .ant-collapse-content {
            background: inherit;
            padding-left: 20px;

            .ant-collapse-content-box a, p {
              display: block;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
    .bottom-side {
      padding: 16px;
    }
  }

  @media all and (max-width: 800px) {

    .bottom-side {
      font-size: 12px;
      flex-direction: column;
      align-items: flex-start;


      .bottom-side-main-content {
        flex-wrap: wrap;
        > div {
          margin-top: 8px;
        }
      }
    }
  }

}

.languages-wrapper {
  //min-width: 82px!important;

  .ant-dropdown-menu{
    width: 82px;
  }
  .language-dropdown-item {
    display: flex;
    align-items: center;
    text-transform: uppercase;


    img {
      width: 20px;
      height: 14px;
      margin-right: 8px;
    }
    &:hover{
      background-color: transparent;
    }
  }
}

.dropdown-menu-item {
  text-transform: uppercase;
  font-size: 14px;
  font-family: Medium;
  letter-spacing: 1px;

  div {
    position: relative;
    top: 1px;
  }

  img {
    width: 24px;
    height: 20px;
    margin-right: 10px;
    border-radius: 1px;
  }

  svg {
    margin-top: -2px;
  }
}