@import '../../../assets/styles/mainVariables';
@import '../../../assets/styles/mixins';

.address-map-modal-wrapper {
  width: auto !important;
  margin-top: 40px!important;

  .info-modal-wrapper {
    min-height: unset;
    max-height: unset;
  }

  .ant-modal-content {
    .ant-modal-body {
      padding: 40px;
    }
  }

  @media screen and (max-width: 820px) {
    width: 100vw !important;
    .ant-modal-content {
      .ant-modal-body {
        padding: 20px;
      }
    }
  }
  @media screen and (max-width: 650px) {

    .ant-modal-content {
      .ant-modal-body {
        padding: 10px;
      }
    }
  }

  .address-map-modal {
    background-color: white;
    width: 700px;

    .top-part {
      width: 100%;
      //padding: 0 30px 5px 30px;

      .modal-header {
        display: flex;
        align-items: center;
        position: relative;

        span {
          margin-left: 15px;
          font-size: 22px;
          font-weight: 600;

          &:first-child {
            margin-left: 0;
          }
        }

      }

      .main-address {
        width: 100%;
        margin-top: 30px;
        position: relative;

        .required-field {
          @include font(Medium, 16);
          color: red;
          line-height: 18px;
        }

        .custom-input-group {
          .suggestions-wrapper {
            position: absolute;
            z-index: 5000;
            top: 100%;
            left: 0;
            width: 100%;
            height: auto;
            max-height: 400px;
            overflow-x: hidden;
            overflow-y: auto;
            border-radius: 4px;
            background: white;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            opacity: 0;
            transition: all 0.2s;
            will-change: opacity;

            //&.active {
            //  opacity: 1;
            //}

            .item {
              width: 100%;
              padding: 5px 12px;
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              border-bottom: 1px solid $borderColor;
              cursor: pointer;
              transition: all 0.2s;
              color: rgba(0, 0, 0, 0.65);
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;

              &:hover {
                background: #d5d5d547;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }

          input {
            &:focus {
              + .suggestions-wrapper {
                opacity: 1;
              }
            }
          }
        }
      }

      .secondary-address {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .custom-input-group {
          width: 100%;
        }
      }
    }

    .map-wrapper {
      padding: 30px 0;

      .map {
        width: 100%;
        height: 260px;
        position: relative;
        border: 1px solid #E6E8EA;
        border-radius: 4px;

        .gm-style {
          position: relative !important;
        }

        .current-location {
          position: absolute;
          right: 0;
          top: 50px;
          z-index: 1000;
          width: 40px;
          height: 40px;
          background: none rgb(255, 255, 255);
          border: 0;
          margin: 10px;
          padding: 0;
          cursor: pointer;
          user-select: none;
          border-radius: 2px;
          box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
          overflow: hidden;
          @include flex-center
        }

        &:first-child {
          .marker-fixed {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -100%) scale(1, 1);
            transition: transform 0.1s;

            #marker-icon {
              background-image: url("../../../assets/images/icons/ic_marker.svg");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              width: 30px;
              height: 50px;
            }

            &.dragging {
              transform: translate(-50%, -120%) scale(1.1, 1.1);
            }
          }
        }

      }
    }

    .modal-actions {
      //padding: 30px 0;
      display: flex;
      justify-content: space-between;

      .link-button {
        width: 48%;
      }
    }

    @media screen and (max-width: 820px) {
      width: 100%;
      .top-part {

        .modal-header {

          span {
            font-size: 18px;
          }

        }

        .custom-input-group {
          margin-bottom: 10px;
        }
      }
    }

    @media screen and (max-height: 650px) {

      .map-wrapper {
        padding: 0 10px;

        .map {
          height: 200px;
        }
      }
    }

    @media screen and (max-height: 700px) {
      .top-part {

        .modal-header {

          span {
            font-size: 18px;
          }

        }

        .custom-input-group {
          margin-bottom: 10px;
        }
      }
    }

    @media screen and (max-width: 400px) {
      //overflow: auto;
      //height: 100vh;
      //padding-top: 0;
      //.top-part {
      //  margin-top: 90px;
      //
      //  .secondary-address {
      //    .custom-input-group {
      //      width: 100%;
      //    }
      //  }
      //}
      //.modal-actions {
      //  padding-bottom: 30px;
      //}
    }
  }

}
