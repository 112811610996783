@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.auth-wrapper {
  width: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;

  .form-content {
    width: 520px;
    height: max-content;
    border-radius: 6px;
    color: white;
    display: flex;
    flex-direction: column;

    .form-logo {
      width: 60%;
      margin: 0 auto 30px;

      svg {
        width: 100%;
      }
    }


    .have-account {
      text-align: center;
      font-size: 13px;
      margin-top: 40px;
      font-family: Medium;
      color: $darkTextColor;

      span {
        font-family: Bold;
        margin-left: 6px;
        color: $brandColor;
      }
    }

    .inner-part {
      width: 100%;
      background-color: white;
      border-radius: 6px;
      padding: 30px 20px;
      margin-bottom: 20px;
      color: $darkTextColor;
      -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

      .form-title {
        text-align: center;
        font-family: Medium;
        color:#202020;
        font-size: 30px;
        margin-bottom: 10px;
      }

      .form-sub-title {
        text-align: center;
        font-size: 14px;
        color: #666;
        margin: 16px 0 34px 0;
        font-family: Medium;

        span {
          margin-left: 6px;
        }
      }
        .hidden-email-field {
          position: absolute;
          opacity: 0;
        }

      .forgot-password {
        font-size: 12px;
        font-family: Medium;
        display: flex;
        justify-content: flex-end;
        color: #248CFF;
        margin-bottom:  30px;

        &:hover {
          color: $brandColor;
        }
      }

      .ant-checkbox-wrapper {
        display: flex;
        align-items: flex-start;
        position: relative;
        top: -2px;

        .ant-checkbox-inner {
          border-color: $brandColor;
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: $brandColor;
          }
        }

        &:hover {
          .ant-checkbox-checked::after {
            border-color: $brandColor;
          }
        }
      }


      .PrimaryButton {
        width: 100%;
        height: calc(#{$elementMainHeight} + 8px);
        margin-bottom: 20px;
        font-family: Medium;
      }
    }

    .under-form-text {
      text-align: center;
      font-size: 15px;
      margin-top: 10px;
      font-family: Medium;
      color: $darkTextColor;

      span {
        color: $brandColor;
        font-family: Bold;
        margin-left: 6px;
      }
      .resend-btn{
        color: $darkTextColor;
       &.active{
         color: $brandColor;
         cursor: pointer;
       }
      }
    }
  }

  .custom-input-group {
    margin-bottom: 20px;
    input {
      border-radius: 4px;
      margin-bottom: 0;
      height: calc(#{$elementMainHeight} + 10px);
      padding: 8px 12px;
      border: 1px solid $borderColor;

      &::placeholder {
        color: #b1b1b1;
        font-size: 12px;
      }

      &.phone {
        padding: 8px 12px 8px 50px;
      }
    }

    .phone-code {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      color: #bfbfbf;
      font-size: 12px;
      border-right: 1px solid #bfbfbf;
      padding-right: 6px;
    }
  }

  //.sign-in {
  //  .custom-input-group {
  //
  //    &:nth-child(3) {
  //      margin-bottom: 30px;
  //    }
  //  }
  //}

  .sign-up {
    //.custom-input-group {
    //
    //  &:nth-child(6) {
    //    margin-bottom: 26px;
    //  }
    //}

    .accept-privacy-policy {
      margin-bottom: 20px;
      display: flex;

      .privacy-policy-checkbox {
        &.error .ant-checkbox-inner {
          border-color: red !important;
        }
      }

      .privacy-checkbox-title {
        margin-left: 10px;
      }
    }

    .privacy-policy-text {
      margin-left: 10px;
      font-size: 12px;

      .privacy-policy-link {
        color: $darkTextColor;
        //margin: 0 5px;
        &:hover {
          color: $brandColor;
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    align-items: center;

    .form-content {
      width: 100%;
      padding: 0 8px;
      margin: 20px 0;
    }
  }
  @media all and (max-width: 450px) {
    .custom-input-group {
      input {
        height: $elementMainHeight;
        font-size: 12px;
        //padding: 0;
      }
    }

    .form-content .inner-part .PrimaryButton {
      height: $elementMainHeight;
    }

    .login-options-wrapper {
      .social-sign-in-btn {
        height: $elementMainHeight;
        &.with-google {
          margin-bottom: 16px;
        }
      }
    }
  }
}
