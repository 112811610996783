@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.header-wrapper {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;

  &.hide-sticky-header {
    //padding: 0 $mainPadding;

    .header-box, .header-categories {
      display: none;
    }
  }

  .service-enter {
    color: #01BB46;
    margin: 0 5px;
  }

  .user-is-blocked-wrapper {
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.05);
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative;
    border-radius: 4px;
    color: black;
    @include font(Medium, 15);
    transition: all 0.2s;
    margin: 20px 0;
    .user-is-blocked-text{
      padding-right: 24px;
    }
    .block-icon {
      margin: 0 40px 0 120px;
      width: 24px;
      height: 24px;
    }

    .close-icon {
      position: absolute;
      right: 20px;
      width: 14px;
      height: 14px;
      cursor: pointer;

      path {
        fill: #3b3e40;
      }
    }
  }

  .show-content {
    visibility: visible;
    opacity: 1;
  }

  .hide-content {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }

  @media all and (max-width: 1200px) {
    .user-is-blocked-wrapper {
      font-size: 14px;

      .block-icon {
        margin: 0 20px 0 40px;
      }
    }
  }

  @media all and (max-width: 1000px) {
    //.header-box,
    .category-list-wrapper {
      display: none;
    }

    .user-is-blocked-wrapper {
      font-size: 12px;
      margin: 70px 0 0 0 ;
      padding: 10px;

      .block-icon {
        margin: 0 10px 0 0;
      }
      .close-icon{
        right: 10px;
      }
    }
    .hide-content {
      margin: 0;
      padding: 0;
    }
  }
  @media all and (max-width: 700px){
    .user-is-blocked-wrapper{
      height: 80px;
      font-size: 11px;
      .block-icon {
        //margin: 0 40px 0 120px;
        width: 44px;
        height: 24px;
      }
      .user-is-blocked-text{
        //padding-left: 34px;
      }
    }
    .hide-content {
      margin: 0;
      height: 0;
    }
  }
}

.auth-header-wrapper{
  padding: 0 $mainPadding;
  height: 60px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
  background-color: white;

  .brand-dark{
    height: 40px;
  }
  img {
    width: 180px;
  }
  @media all and (max-width: 800px){
    img{
      width: 120px;
    }
  }
}
