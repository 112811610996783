@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";
@import "../../assets/styles/mixins";

.verify-email-wrapper {
  .inner-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;

    .text {
      font-size: 16px;
      text-align: center;
      color: #282828;
      line-height: 36px;

      span {
        font-family: Medium;

        &.active {
          color: $brandColor;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .resend-btn {
        font-family: Bold;
      }
    }

    .email {
      font-size: 16px;
      color: black;
      font-family: Bold;
    }

    .seconds {
      font-size: 22px;
      color: $brandColor;
      font-family: Medium;
      margin-top: 20px;
    }

    .code-container {
      //margin: 25px 0 35px;

      .custom-input-group {
        margin: 0 10px;
        color: $brandColor;
      }

      .code-items{
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .timer-wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        visibility: visible;
        opacity: 1;
        transition: all 0.2s;
        padding: 0 10px;
        margin-bottom: 20px;

        .timer-text{
          //font-size: ;
          color: black;
        }
        .timer-seconds{
          font-family: Medium;
          color: $redColor;
        }
        &.hide{
          opacity: 0;
          visibility: hidden;
        }
      }

      .code {
        border-radius: 4px;
        font-size: 24px;
        height: 50px;
        width: 50px;
        margin-bottom: 0;
        border: 2px solid #eee;
        text-align: center;
        font-weight: 300;
        -moz-appearance: textfield;

        &:valid {
          border-color: $brandColor;
          box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.1);
        }

        &:focus {
          outline: none;
          border-color: $brandColor;
        }
      }

      .code::-webkit-outer-spin-button,
      .code::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .PrimaryButton{
        width: calc(100% - 20px);
        margin: 35px 10px 0 10px;
      }
    }
  }

  .resend_email_text {
    color: #3D3D3D;
  }

  @media (max-width: 800px) {
    .inner-part {
      padding: 60px 30px;

      .email {
        font-size: 15px;
      }

    }
  }
  @media (max-width: 450px) {
    .inner-part {
      padding: 30px 20px;

      .mail-icon {
        margin-bottom: 20px;
        width: 80px;
        height: 80px;
      }

      .text {
        line-height: 26px;
      }

      .seconds {
        font-size: 18px;
        margin-top: 8px;
      }

      .code-container {
        margin: 16px 0 20px;
        .custom-input-group {
          margin: 0 4px;
        }

        .code {
          width: 36px;
          height: 36px;
          padding: 8px 4px;
        }
      }

      .send-btn {
        width: 255px;
        height: 36px;
        font-size: 14px;
      }
    }
  }
}
