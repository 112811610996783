@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.slider-wrapper {
  width: calc(100% - 360px);
  margin-right: 40px;

  .image-wrapper {
    width: 100%;
    position: relative;

    .main-slider-items {
      position: relative;
      width: 100%;
      padding-top: 41.6%;

      .main-slider-inner-part {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .arrows {
          transition: .2s;
          visibility: hidden;
          opacity: 0;
        }

        &:hover {
          .arrows {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    .slick-slider {
      height: 100%;

      .slick-dots {
        display: flex;
        justify-content: center;
        list-style-type: none;

        li {
          margin: 0 6px;
          width: 30px;
          height: 2px;
        }

        button {
          display: block;
          width: 100%;
          height: 100%;
          padding: 0;
          border: none;
          border-radius: 10px;
          background-color: #BEBEC3;

          text-indent: -9999px;
        }

        li.slick-active button {
          background-color: $brandColor;
        }
      }

      .slider-item {
        position: relative;
        width: 100%;
        padding-top: 41.6%;

        &.slide-link {
          cursor: pointer;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 6px;
        }

        .slider-info-wrapper {
          position: absolute;
          top: 0;
          left: 7.5%;
          color: black;
          height: 100%;
          width: 92.5%;
          display: flex;
          align-items: center;

          .info-content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 66%;
            width: 100%;
            position: relative;

            .PrimaryButton {
              text-transform: uppercase;
            }
          }

          .slide-title {
            @include font(Bold, 50);
            overflow: hidden;
            line-height: 62px;
            width: 55%;
            min-width: 300px;
            height: 130px;

            span {
              color: $brandSecColor;
            }
          }

          .slide-sub-title {
            font-size: 16px;
            width: 41%;
            height: 50px;
            overflow: hidden;
            letter-spacing: 0.23px;
          }

          .PrimaryButton {
            width: 160px;
            height: 46px;
            border-radius: 4px;
            background-color: $brandColor;
          }
        }
      }
    }
  }

  @media all and (max-width: 1600px) {

    .image-wrapper {

      .slick-slider {

        .slider-item {

          .slider-info-wrapper {

            .slide-title {
              font-size: 38px;
              line-height: 50px;
              height: 100px;
            }

            .PrimaryButton {
              width: 182px;
              height: 52px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1500px) {
    width: calc(100% - 320px);
  }
  @media all and (max-width: 1260px) {
    .image-wrapper {

      .slick-slider {

        .slider-item {

          .slider-info-wrapper {

            .slide-sub-title {
              font-size: 18px;
              height: 86px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1160px) {
    .image-wrapper {
      .slick-slider {

        .slider-item {

          .slider-info-wrapper {

            .slide-title {
              font-size: 32px;
              line-height: 40px;
              height: 78px;
            }

            .slide-sub-title {
              @include font(Medium, 16);
              width: 42%;
              height: 74px;
            }

            .PrimaryButton {
              width: 140px;
              height: 42px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1040px) {
    width: 100%;
    .image-wrapper {
      .slick-slider {

        .slider-item {

          .slider-info-wrapper {

            .slide-title {
              font-size: 28px;
              line-height: 38px;
              height: 84px;
            }

            .slide-sub-title {
              @include font(Medium, 14);
              width: 40%;
              height: 68px;
            }

            .PrimaryButton {
              width: 115px;
              height: 40px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 800px) {
    .image-wrapper {
      .slick-slider {

        .slider-item {

          .slider-info-wrapper {

            .info-content {
              height: 70%;
            }

            .slide-title {
              height: 74px;
            }

            .slide-sub-title {
              @include font(Medium, 13);
              width: 40%;
              height: 58px;
            }

            .PrimaryButton {
              width: 90px;
              height: 35px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .image-wrapper {
      width: 100%;
      position: relative;

      .main-slider-items {
        padding-top: 125.9%;
      }

      .slick-slider {

        .slider-item {
          padding-top: 125.9%;

          .slider-info-wrapper {
            align-items: flex-start;
            left: 5%;

            .info-content {
              padding-top: 8%;
              height: unset;
            }

            .slide-title {
              font-size: 28px;
              line-height: 32px;
              height: unset;
              max-height: 70px;
              width: 90%;
              margin-bottom: 24px;
              min-width: 240px;
            }

            .slide-sub-title {
              @include font(Medium, 16);
              width: 90%;
              height: unset;
              max-height: 78px;
              margin-bottom: 24px;
            }

            .PrimaryButton {
              width: 120px;
              height: 40px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 360px) {
    .image-wrapper {
      .slick-slider {

        .slider-item {

          .slider-info-wrapper {


            .slide-title {
              font-size: 20px;
              line-height: 24px;
              height: 48px;
              margin-bottom: 12px;
            }

            .slide-sub-title {
              font-size: 13px;
              line-height: 18px;
              height: 36px;
              margin-bottom: 18px;
            }

            .PrimaryButton {
              width: 100px;
              height: 30px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  //@media all and (max-width: 400px) {
  //  .image-wrapper {
  //    .slick-slider {
  //
  //      .slider-item {
  //
  //        .slider-info-wrapper {
  //          .slide-title {
  //            @include font(Bold, 14);
  //            line-height: 18px;
  //            width: 55%;
  //            height: 34px;
  //          }
  //
  //          .slide-sub-title {
  //            @include font(Medium, 10);
  //            line-height: 12px;
  //            width: 46%;
  //            height: 23px;
  //          }
  //
  //          .PrimaryButton {
  //            width: 70px;
  //            height: 22px;
  //            font-size: 9px;
  //          }
  //        }
  //      }
  //    }
  //  }
  //}
}
