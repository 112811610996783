@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

$padding-right-ld: calc(var(--mainPadding) - 20px);
$padding-right-md: calc(var(--mainPadding) - 10px);

.products-list-wrapper {
  //width: calc(100% - var(--asideContentWidth));
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .product-card-wrapper {
    width: calc(100% / 4);
  }

  .no-products-wrapper {
    width: 100%;
    height: 100%;
    padding: 139px 0;
    @include flex-center;
    flex-direction: column;

    .no-result-text {
      margin: 20px 0;
      color: black;
      @include font(Medium, 16);
    }
  }
}

@media all and (min-width: 1701px) {
  .products-list-wrapper {
    .product-card-wrapper {
      width: calc(100% / 5);
    }
  }
}
@media all and (max-width: 1700px) {
  .products-list-wrapper {

    .product-card-wrapper {
      width: calc(100% / 4);
    }
  }
}
@media all and (max-width: 1500px) {
  .products-list-wrapper {

    .product-grid-card {
      width: calc(100% / 3);
    }
  }
}

@media all and (max-width: 1160px) {
  .products-list-wrapper {

    .product-grid-card {
      width: calc(100% / 2);
    }
  }
}


@media all and (max-width: 900px) {
  .products-list-wrapper {
    padding-right: 0;
    padding-left: 0;
    .product-grid-card {
      width: calc(100% / 3);
    }
    .product-list-card {
      width: 100%;
      padding: 12px;
      .bottom-side {
        justify-content: space-between;
      }
    }
  }
}

@media all and (max-width: 700px) {
  .products-list-wrapper {
    width: 100%;
    padding: 0;
    .product-grid-card {
      width: calc(100% / 2);
    }

    .no-products-wrapper {
      .no-products {
        width: 200px;
        height: 200px;
      }

      .no-result-text {
        font-size: 16px;
      }
    }
  }
}
@media all and (max-width: 500px) {
  .products-list-wrapper {
    width: 100%;
    .product-grid-card {
      width: 100% ;
    }
  }
}
