@import '../../../../assets/styles/mainVariables';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/fonts';


.product-card {
  z-index: 1;

  .fav-btn {
    width: $elementMainHeight;
    height: $elementMainHeight;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.06);
    transition: .2s;
    z-index: 1;

    .title {
      display: flex;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
      //box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    }
  }

  .stars-count {
    align-items: center;
    margin-top: 12px;

    .product-rating {
      @include font(Medium, 16px);
      margin-left: 15px;
      margin-bottom: 1px;
    }

    .fill-star-icon {
      path {
        fill: $brandSecColor;
      }
    }
  }

  .product-badge {
    left: 8px;
    right: unset;
  }

  .price-wrapper {
    display: flex;
    align-items: center;

    .price {
      display: flex;
      font-size: 22px;
      font-family: Bold;
      letter-spacing: .6px;

      &.new-price{
        color: $redColor;
      }
    }

    .old-price {
      display: flex;
      text-decoration: line-through;
      color: #a7a7a7;
      @include font(Regular, 18);
      margin-left: 12px;
    }
  }

  .product-main-content {
    .product-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      margin-top: 10px;
      transition: .2s;
      letter-spacing: .6px;
      overflow: hidden;
      height: 42px;
      //text-overflow: ellipsis;
      //white-space: nowrap;
    }

    .product-description-wrapper {
      overflow: hidden;
      height: 44px;
      font-size: 14px;
      margin-top: 10px;

      .more-link {
        color: #000;
        transition: .2s;

        &:hover {
          color: $brandColor;
        }
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    top: -1px;
    z-index: -1;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }

  &:hover:before {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    background: #fff;
  }

  .bottom-side {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;

    .basket-wrapper {
      display: flex;
      width: calc(100% - #{$elementMainHeight} - 12px);

      .plus-minus-wrapper {
        background-color: $greyBgColor;
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        width: 100%;
        border-radius: 4px;

        .down, .up {
          width: $elementMainHeight;
          height: $elementMainHeight;
          color: $brandColor;
          background-color: transparent;
          font-size: 30px;
          border-radius: 4px;
          @include flex-center;


          svg {
            path, rect {
              fill: $brandColor;
            }
          }
        }
      }

      .basket-btn {
        width: 100%;
      }
    }

    &.fav-btn {
      background-color: rgba(0, 0, 0, 0.06);
      border: none;
    }

    .product-actions-part {
      display: flex;
    }
  }

  &:hover .bottom-side button.cart-btn {
    //background-color: $brandSecColor!important;
    //border-color: $brandSecColor!important;
    //color: white !important;
  }

  &:hover .product-main-content .product-title {
    color: $brandColor;
  }
}

.product-grid-card {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 20px;
  transition: box-shadow .2s;
  position: relative;
  height: 100%;

  .price-wrapper {
    margin-top: 4px;
  }

  .product-image-proportion {
    padding-top: 100%;
    position: relative;
    width: 100%;

    .product-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:nth-child(4n) {
    border-right-color: transparent;
  }

  &:nth-last-child(2) {
    border-right-color: transparent;
  }

  @media all and (max-width: 800px) {
    padding: 16px 13px;
    .price-wrapper {

      .price {
        font-size: 18px;
        letter-spacing: .2px;
      }

      .old-price {
        font-size: 16px;
        letter-spacing: .2px;
      }

      .date {
        font-size: 11px;
      }
    }

    .product-main-content {
      .product-title {
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px;
      }

      .product-description-wrapper {
        overflow: hidden;
        font-size: 14px;
        margin-top: 4px;
      }
    }
    .bottom-side {
      margin-top: 12px;
    }
  }

  @media all and (max-width: 680px) {
    width: calc(100% / 2);
  }
  @media all and (max-width: 500px) {
    padding: 16px;
  }

  @media all and (max-width: 480px) {
    width: 100%;
    padding: 16px;
    .product-img {
      width: 80%;
      margin: 10px auto;
    }
    &:nth-child(2n) {
      border-right: 1px solid $borderColor;
    }
    &:nth-child(n) {
      border-right-color: transparent;
    }
  }
  @media all and (max-width: 400px) {
    width: 100%;
    .product-img {
      width: 100%;
      margin: 0 auto;
    }
    &:nth-child(2n) {
      border-right: 1px solid $borderColor;
    }
    &:nth-child(n) {
      border-right-color: transparent;
    }
  }
}

.product-list-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid $borderColor;
  padding: 20px;
  transition: box-shadow .2s;
  position: relative;


  .product-img {
    object-fit: cover;
    width: 120px;
    height: 120px;
  }

  .right-side {
    width: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .product-title-wrapper {
      margin-top: -4px;
      display: flex;
      justify-content: space-between;

      .product-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        margin-top: 10px;
        transition: .2s;
        letter-spacing: .6px;
        overflow: hidden;
        height: 42px;
      }

      .fav-btn {
        background-color: transparent;
      }
    }

    .product-main-content {
      .product-description-wrapper {
        height: unset;
      }


      .bottom-side, .stars-count {
        margin-top: 0;
      }
    }

    .price-wrapper {
      position: relative;
      //top: 10px;
      margin-right: 40px;

      .price {
        font-size: 24px;

        span {
          margin-left: 6px;
        }
      }

      .old-price {
        span {
          margin-left: 6px;
        }
      }
    }

    .basket-wrapper {
      min-width: 160px;
      width: unset;
      flex-grow: unset;

      .basket-btn {
        width: 100%;
      }
    }

    .product-actions-part {
      flex-grow: 1;
      justify-content: flex-end;
    }

    .address-wrapper {
      display: flex;
      align-items: center;
      font-family: Medium;
      margin-top: 4px;

      span {
        color: #808080;
        overflow: hidden;
        height: 26px;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;

      }

      svg {
        margin-left: -8px;
      }
    }

    .status-wrapper {
      display: flex;
      font-family: Medium;
      color: rgba(0, 0, 0, .54);
      margin-top: 10px;

      .product-badge {
        position: unset;
        margin-right: 8px;
      }
    }
  }

  @media all and (max-width: 900px) {
    align-items: flex-start;
    .product-img {
      width: 130px;
      height: 130px;
    }
    .right-side {
      width: calc(100% - 142px);
    }
  }
  @media all and (max-width: 800px) {
    padding: 20px 10px;

    .product-actions-part {
      .block-btn {
        border-radius: 0 2px 2px 0 !important;
      }
    }
    .product-img {
      width: 120px;
      height: 120px;
    }
    .right-side {
      width: calc(100% - 132px);

      .product-main-content {
        .product-title {

          margin-top: 2px;
        }

        .product-description-wrapper {
          margin-top: 5px;
        }
      }

      .cart-wrapper {
        .cart-btn {
          flex-grow: unset;
        }
      }

      .address-wrapper {
        margin-top: 0;
      }

      .status-wrapper {
        margin-top: 0;
      }

      .bottom-side {
        margin-top: 8px;

        .product-actions-part {
          flex-grow: unset;
        }
      }
    }
  }
  @media all and (max-width: 600px) {

    .product-image-proportion {

      .status-wrapper {

        .product-badge {
          min-width: 54px;
          padding: 0 12px;
          height: 20px;
        }
      }
    }

    .right-side {

      .product-title-wrapper {
        .product-title {
          margin-bottom: 8px;
        }
      }

      .bottom-side {
        .cart-wrapper {
          //flex-grow: unset;

          .cart-btn {
            display: none;
          }
        }

        .price-wrapper {
          .price {
            font-size: 17px;
          }

          .old-price {
            font-size: 15px;
          }
        }

        .basket-wrapper {
          min-width: 120px;

          .plus-minus-wrapper {
            .up, .down {
              width: 36px;
              height: 36px;

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }

          .basket-btn {
            padding: 0 12px;
            height: 36px;
          }
        }

        .product-actions-part {
          flex-grow: unset;
        }
      }
    }
  }
  @media all and (max-width: 480px) {
    padding: 12px 10px;

    .product-image-proportion {

      .product-img {
        width: 112px;
        height: 112px;
      }
    }

    .right-side {
      width: calc(100% - 124px);

      .price-wrapper {
        margin: 0 0 8px 0;
      }

      .basket-wrapper {
        width: 100%;
      }

      .bottom-side {
        flex-direction: column;
        margin-top: 4px;
        align-items: flex-start;
      }
    }
  }

  @media all and (max-width: 400px) {

    .right-side {

      .product-title-wrapper {
        .product-title {
          font-size: 14px;
        }
      }
    }
  }

}

.user-info-dropdown-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  position: relative;

  .clear-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
  }

  .user-info {
    display: flex;
    align-items: center;
    padding: 16px 40px 16px 16px;
    border-bottom: 2px solid #F0F0F0;

    .user-img {
      width: 45px;
      height: 45px;
      object-fit: cover;
      margin-right: 12px;
      border-radius: 2px;
    }

    .user-info-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .user-name {
        font-size: 18px;
        font-family: Medium;
      }

      .user-address {
        font-size: 14px;
        line-height: 18px;
        color: #808080;
      }
    }
  }

  .user-phones-wrapper {
    padding: 16px;

    &.without-user-info {
      padding-top: 40px;
    }

    .phone-item {
      font-size: 16px;
      margin-bottom: 8px;
      padding: 7px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F0F0F0;
      border-radius: 2px;

      .phone-type {
        margin-right: 20px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
          border-radius: 2px;
        }
      }

      .phone-number {
        cursor: pointer;
      }
    }
  }
}

.header-phone-dropdown {
  z-index: 10000 !important;
}




