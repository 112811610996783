@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.mobile-menu-wrapper {
  position: fixed;
  top: 96px;
  left: 0;
  z-index: 9901;
  width: 100%;
  height: 100vh;
  overflow: auto;
  transition: all 0.35s cubic-bezier(.39, .575, .565, 1);
  visibility: hidden;
  opacity: 0;
  display: none;
  @media all and (max-width: 1000px) {
    display: block;
  }

  .right-arrow {
    height: 10px;
    position: relative;
    left: -2px;
    path {
      fill: black !important;
    }
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }

  .mobile-menu-content {
    width: 350px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 20px;
    z-index: 9002;
    height: 100%;
    background-color: white;
    overflow-y: auto;


    &::-webkit-scrollbar {
      width: 0;
    }

    .ant-dropdown-trigger {
      margin-top: 10px;
      color: $brandColor;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .arrow-icon {
        width: 14px;
        height: 14px;
        transform: rotate(90deg);
        margin: 0 30px 0 8px;
      }
    }

    .shipping-address-btn {
      @include flex-center;
      white-space: nowrap;
      background-color: #F2F3F5;
      color: black;
      height: 45px;
      width: calc(100% + 40px);
      margin: 0 -20px;
      padding: 0;

      .title {
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        justify-content: center;


        span {
          margin-top: 2px;
          padding-left: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include font(Medium, 13)

        }
      }
    }

    .ant-menu-root > .ant-menu-item {
      border-bottom: 2px solid $greyBorderColor;
      position: relative;

      .badge {
        position: absolute;
        bottom: 10px;
        right: 0;
      }
    }

    .ant-menu-root > .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding-left: 0 !important;
        border-bottom: 2px solid $greyBorderColor;
        transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
          color: $brandColor;
        }
      }

      .ant-menu {
        &.ant-menu-sub {
          padding-left: 20px;
        }
        .ant-menu-submenu-title {
          border-bottom: 0;
        }
      }
    }

    .ant-menu-item:active, .ant-menu-submenu-title:active {
      background-color: white;
    }

    .ant-menu-item-selected {
      color: $brandColor;
      a {
        color: $brandColor;
        &:hover {
          color: $brandColor;
        }
      }
    }

    .ant-menu {
      width: 100%;
      //margin-top: 10px;
      background-color: transparent;

      .ant-menu-item {
        background-color: transparent;
        color: $greyColor;
        border-right: 0;
        padding-left: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2px 0;

        a {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            color: $brandColor;
          }
        }

        a {
          width: 100%;
          height: 100%;
        }

        &::after {
          border-right: 0 !important;
        }
      }
    }

    .ant-menu-inline {
      border-right: 0;
      .ant-menu-submenu-title {
        margin: 0;
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        height: 45px;

        &:hover {
          color: $brandColor;
        }
      }
      &:hover {
        i {
          color: $brandColor;
        }
      }
      &.ant-menu-submenu-selected {
        color: $brandColor;
        i {
          color: $brandColor;
        }
      }
    }

    nav {
      width: 100%;
      display: flex;
      flex-direction: column;

      .menu-item {
        padding: 12px 0;
        border-bottom: 2px solid #E6E8EA;
        //color: $brandColor;
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;

      .menu-button {
        width: 48%;
        height: 40px;
        background-color: $greyColor;
        @include flex-center;
        border-radius: 4px;
        color: black;
      }
    }
  }

  .mobile-menu-overlay {
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9000;
    opacity: 0.55;
  }

  .categories-first-sub, .categories-second-sub {
    width: 100%;
    height: calc(100vh - 100px);
    overflow: auto;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;

    .header {
      background-color: $greyColor;
      height: 45px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      position: fixed;
      width: 100%;
      @media all and (min-width: 600px) {
        max-width: 350px;
      }

      .left-arrow {
        width: 12px;
        height: 12px;
        margin-top: -3px;
        transform: rotate(180deg);
        margin-right: 10px;
      }
    }

    .category-item {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      cursor: pointer;
      margin-bottom: 5px;

      &:hover {
        color: $brandColor;
      }
    }

    [class~=category-item]:nth-child(2) {
      margin-top: 45px
    }
  }

  .categories-first-sub {
    z-index: 10000;
  }

  .categories-second-sub {
    z-index: 10001;
  }

  & {
    .hide {
      display: none;
    }
  }


}

.ant-dropdown {
  z-index: 10003;
}

@media all and (max-width: 600px) {
  .mobile-menu-wrapper {
    padding-top: 20px;

    .mobile-menu-content {
      padding: 0 $mainPadding $mainPadding;
      width: 100%;

      .search_box {
        height: 36px;
        padding: 5px;

        .input-group-append {
          .search_btn {
            padding-right: 5px;

            svg {
              width: 20px;
            }
          }
        }

        .search {
          font-size: 13px;
        }
      }

      .ant-menu {
        margin-top: 5px;
      }
    }
  }
}