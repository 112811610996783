@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.cart-wrapper {
  padding: 0 $mainPadding;
  //background-color: $mainBgColor;
  min-height: calc(100vh - 80px);
  justify-content: space-between;

  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0;
    @include font(Medium, 24);
    color: black;
  }

  &.cart-empty {
    background-color: white;
    justify-content: center;
    align-items: center;
    height: 70vh;

    .cart-empty-section {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: center;
      @include font(Medium, 16);
      margin: 100px 0;

      img {
        width: 260px;

        @media all and (max-width: 600px){
          width: 160px;
        }
      }

      span {
        color: black;
        margin: 15px 0;
      }

      a {
        background-color: $brandColor;
        padding: 12px 20px;
        margin-top: 12px;
        color: white;
        font-size: 14px;
        border-radius: 6px;
      }
    }
  }

  .cart-main-content {
    display: flex;
    border-radius: 4px;

    .card-list {
      width: 68%;
      border: 1px solid $borderColor;

      .cart-products {
        max-height: 560px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 5px;
          background-color: #E9E9E9;
          position: absolute;
          top: 0;
          right: 25px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 15px;
          background-color: #C4C4C4;
        }
      }

      .clear-cart {
        color: $redColor;
        font-size: 14px;
        @include font(Medium, 13);
        cursor: pointer;
        padding: 20px 16px;
        text-align: right;
      }
    }

    .payment-details {
      width: 32%;
      background-color: $greyBgColor;
      border: 1px solid $borderColor;
      border-left: 0;
      padding: 26px 32px;

      .payment-details-title {
        margin: 6px 0 10px;
        @include font(Medium, 18);
        color: black;
      }

      .payment-details-content {
        width: 100%;
        background-color: white;
        border-radius: 4px;
        padding: 24px;

        .summery-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          color: #75757B;
          @include font(Medium, 14);
          letter-spacing: 0.4px;
          font-weight: 500;

          .discounted_amount {
            color: #F54641;
          }

          .line {
            position: relative;
            top: 3px;
            margin: 0 7px;
            flex-grow: 1;
            border-top: 1px dotted #BFBCC2;
          }

          .item-number {
            color: black;
            &.shipping-amount {
              color: $brandColor;
            }
          }
        }

        .payable-amount {
          display: flex;
          color: black;
          justify-content: space-between;
          margin: 26px 0 30px;
          .item-number {
            font-size: 18px;
            position: relative;
            top: -2px;
          }
        }

        .PrimaryButton {
          width: 100%;
        }

        .promoCode-description {
          color: #c3c3c3;
          margin-top: 16px;
          font-size: 11px;
          line-height: 15px;
          letter-spacing: 0.5px;
        }
      }

      .promoCode {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;

        .promoCode-input {
          width: 100%;
          display: flex;
          align-items: flex-end;
          input {
            border: none;
          }

          input {
            border-color: $greyBorderColor;
            &:disabled {
              background-color: white;
            }
          }

          .custom-input-group {
            flex-grow: 1;
            margin-bottom: 0;

            input {
              margin-bottom: 0;
              border-right: 0;
              border-radius: 4px 0 0 4px;
              //height: 55px;
            }

            label {
              @include font(Medium, 20);
              color: black;
            }
          }

          .PrimaryButton {
            transition: all 0.2s ease-in-out;
            border-radius: 0 4px 4px 0;

            .title {
              display: flex;
            }

            &:disabled {
              background-color: #d3d3d3 !important;
            }

            &:disabled:hover {
              opacity: .8;
            }

            &:hover {
              opacity: 1;

              svg {
                path {
                  fill: white;
                }
              }
            }
          }
        }

        .checkCode-message {
          height: 24px;
          width: 100%;
          margin-top: 6px;
          font-size: 13px;
          color: black;
        }

        img {
          width: 16px;
        }
      }

      .bonus-points-text {
        text-align: center;
        margin-top: 8px;
        color: black;

        span {
          color: $brandColor;
          font-family: Medium;
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    min-height: calc(100vh - 70px);
  }

  @media all and (max-width: 1300px) {
    .card-list {
      width: 67%;
    }
    .payment-details {
      width: 33%;
    }
  }

  @media screen and (max-width: 1150px) {
    .card-list {
      .cart-header {
        margin: 30px 0;

        .cards-count {
          font-size: 22px;
        }

        .clear-cart {
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {

    .cart-main-content {
      flex-direction: column;
      .card-list {
        width: 100%;

        .cart-header {
          margin: 20px 0;

          .cards-count {
            font-size: 22px;
          }

          .clear-cart {
            font-size: 14px;
          }
        }
      }

      .payment-details {
        width: 100%;
        margin-bottom: 20px;
        border-top: none;
        border-left: 1px solid $borderColor;
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0;
  }
  @media screen and (max-width: 600px) {
    .cart-main-content {
      .payment-details {
        padding: 12px 24px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .card-list {

      .cart-header {

        .cards-count {
          font-size: 18px;
        }

        .clear-cart {
          font-size: 13px;
        }
      }
    }

    .payment-details {
      .payment-details-content{
        .payable-amount{
          font-size: 18px;
        }
      }
    }
  }
}
