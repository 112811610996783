@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.cart-order-wrapper {
  padding: 0 $mainPadding;
  min-height: calc(100vh - 80px);

  .page-content {
    display: flex;
    justify-content: space-between;
    border: 1px solid $borderColor;
    border-radius: 6px;
  }

  .content-title {
    color: black;
    @include font(Medium, 22);
    margin-bottom: 20px;
  }

  .content-subtitle {
    color: black;
    @include font(Medium, 18);
    padding-bottom: 20px;
  }

  .left-part {
    padding: 20px 24px;
    width: 68%;
    background-color: white;
    border-right: 1px solid $borderColor;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    .form-wrapper {
      width: 100%;

      .custom-input-group {
        margin-bottom: 16px;

        label {
          color: #333333;
          margin-bottom: 12px;
          @include font(Medium, 14);
          letter-spacing: 0.5px;
        }

        input {
          font-size: 16px;
        }
      }

      .main-address {
        width: 100%;
        position: relative;

        .custom-input-group {
          .suggestions-wrapper {
            position: absolute;
            z-index: 5000;
            top: 100%;
            left: 0;
            width: 100%;
            height: auto;
            max-height: 400px;
            overflow-x: hidden;
            overflow-y: auto;
            border-radius: 4px;
            background: white;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            opacity: 0;
            transition: all 0.2s;
            will-change: opacity;

            //&.active {
            //  opacity: 1;
            //}

            .item {
              width: 100%;
              padding: 5px 12px;
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              border-bottom: 1px solid $borderColor;
              cursor: pointer;
              transition: all 0.2s;
              color: rgba(0, 0, 0, 0.65);
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;

              &:hover {
                background: #d5d5d547;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }

          label {

            span {
              position: relative;

              .required-field {
                position: absolute;
                right: -10px;
                top: 0px;
                z-index: 1;
                @include font(Medium, 16);
                color: red;
                line-height: 18px;
              }
            }
          }

          input {
            &:focus {
              + .suggestions-wrapper {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .map {
      width: 100%;
      height: 350px;
      position: relative;
      border-radius: 4px;


      .current-location, .full-window {
        position: absolute;
        z-index: 2;
        width: 40px;
        height: 40px;
        background: none rgb(255, 255, 255);
        border: 0;
        margin: 10px;
        padding: 0;
        cursor: pointer;
        user-select: none;
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        overflow: hidden;
        @include flex-center
      }

      .current-location {
        right: 0;
        top: 50px;
      }

      .full-window {
        right: 0;
        top: 0;
      }


      .marker-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
      }

      .marker-fixed {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%) scale(1, 1);
        transition: transform 0.1s;
        //display: none;

        #marker-icon {
          background-image: url("../../assets/images/icons/ic_marker.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 30px;
          height: 50px;
        }

        &.dragging {
          transform: translate(-50%, -120%) scale(1.1, 1.1);
        }

        &:not(:nth-of-type(2)) {
          display: none;
        }
      }

    }
  }

  .right-part {
    position: relative;
    background-color: white;
    width: 32%;
    padding: 20px 24px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    .content-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .custom-input-group {
      margin-bottom: 16px;

      label {
        @include font(Medium, 14);
        color: #333333;
        margin-bottom: 12px;
      }
    }

    .order-info {
      /*.payment-checks {
        margin-top: 10px;

        .ant-radio-group {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .ant-radio-wrapper {
            font-size: 13px;
            margin-right: 0;
            color: black;

            .ant-radio {
              color: $brandColor;
              vertical-align: middle;

              .ant-radio-input {
                width: 16px;
                height: 16px;

                &:not([type='range']) {
                  touch-action: unset;
                }
              }
            }

            .ant-radio-inner {
              border: 5px solid $brandColor;
              border-color: $brandColor !important;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              //margin-right: 10px;

              &::after {
                width: 8px;
                height: 8px;
                //top: 1px;
                //left: 2px;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                background-color: white;
                //border: 1px solid $brandColor;
                border: 0;
                border-radius: 50%;
              }
            }

            &.ant-radio-wrapper-disabled {
              .ant-radio {
                //color: rgba(0, 0, 0, 0.25);
              }

              .ant-radio-inner {
                border: 3px solid $greyBorderColor;
                border-color: $greyBorderColor !important;
              }
            }

            &:first-child {
              .ant-radio-inner {
                &::after {
                  //left: 1px;
                }
              }
            }
          }

          .ant-radio-checked::after {
            border: 0;
          }
        }
      }
*/
      .custom-input-group{
       .phone-input{
          padding-left: 56px;
        }
      }
    }

    .order-text {
      border-top: 2px solid $greyBgColor;
      margin-top: 30px;
      padding-top: 30px;

      span {
        font-size: 13px;
        line-height: 18px;
        color: #606060;
      }

      a {
        color: #007aff;
        //text-decoration: underline;
      }

      .ant-checkbox-wrapper {
        display: flex;
        align-items: flex-start;

        .ant-checkbox {
          margin-right: 8px;
          margin-top: 3px;
        }
      }

      .ant-checkbox-wrapper {
        .ant-checkbox-inner {
          border-color: $brandColor;
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: $brandColor;
          }
        }

        &:hover {
          .ant-checkbox-checked::after {
            border-color: $brandColor;
          }
        }
      }

      &.error .ant-checkbox-wrapper {
        .ant-checkbox-inner {
          border-color: #F34E42 !important;
        }
      }
    }

    .confirm-button {
      width: 100%;
      text-transform: uppercase;
      margin-top: 30px;
      font-size: 15px;
    }
  }

  @media screen and (max-width: 1400px) {
    .left-part {
      width: 65%;
    }
    .right-part {
      width: 35%;
    }
  }

  @media screen and (max-width: 1150px) {
    .left-part {
      width: 62%;

      .map {
        height: 320px;
      }
    }

    .right-part {
      width: 38%;
    }
  }

  @media screen and (max-width: 1000px) {
    .content-title {
      margin-top: 30px;
    }
    .page-content {
      display: flex;
      flex-direction: column;

      .left-part, .right-part {
        width: 100%;
        border-right: 0;
        border-radius: 6px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 40px;
    .content-title {
      font-size: 20px;
    }

    .left-part {
      padding: 10px;

      .form-wrapper {
        .custom-input-group {
          label {
            @include font(Medium, 14);
          }
        }
      }

      .map {
        height: 300px;
      }
    }

    .right-part {
      padding: 20px 10px 30px;

      .order-text {

        span {
          font-size: 12px;
        }
      }

    }
  }

  @media screen and (max-width: 450px) {
    padding: 0 0 40px 0;
    .right-part {

    }
  }
}
