@import '../../../assets/styles/mainVariables';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';

.custom-header-content {
  width: $contentWidth;
  margin: auto;
  position: relative;

  .image-wrapper {
    width: 100%;
    padding-top: 24.2%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //padding: 1px;
      border-radius: 8px;
      object-fit: contain;
    }
  }

  .custom-header-info {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 60px;

    .custom-title {
      font-size: 50px;
      line-height: 60px;
      font-family: Bold;
      margin-bottom: 30px;
      width: 45%;
    }

    .custom-description {
      width: 60%;
      height: 82px;
      font-size: 20px;
      overflow: hidden;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .custom-header-content {
    //padding: 0 $mainPadding;

    .custom-header-info {
      //padding: 0 $mainPadding;

      .custom-title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 20px;
      }

      .custom-description {
        height: 64px;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .custom-header-content {

    .custom-header-info {
      padding: 0 $mainPadding;

      .custom-title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 20px;
      }

      .custom-description {
        height: 38px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .custom-header-content {
    height: 200px;
    .image-wrapper {
      overflow: hidden;
      padding-top: 200px;
      img {
        object-fit: cover;
        left: unset;
        width: unset;
        right: 0;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .custom-header-content {

    .custom-header-info {

      .custom-title {
        width: 80%;
        font-size: 24px;
        line-height: 30px;
        margin: 0;
      }

      .custom-description {
         display: none;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .custom-header-content {
    height: 160px;
    .image-wrapper {
      padding-top: 160px;
    }
    .custom-header-info {

      .custom-title {
        width: 80%;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}


