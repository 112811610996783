@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.products-wrapper {
  padding: 0 0 $mainPadding;
  position: relative;
  min-height: calc(100vh - 120px);

  .up-part {
    position: relative;

    .banner-wrapper {
      position: relative;

      &.link-banner {
        cursor: pointer;
      }

      .clear-btn {
        position: absolute;
        top: 10px;
        right: 14px;
        z-index: 10;
        cursor: pointer;
      }

        .banner-content {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .banner-item {
            width: 100%;
            border-radius: 6px;

            .image-wrapper {
              width: 100%;
              padding-top: 24.2%;
              position: relative;

              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                object-fit: cover;
              }

              .inner-side {
                position: absolute;
                top: 0;
                z-index: 1;
                color: #222;
                width: 60%;
                height: 100%;
                padding: 4%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                  font-size: 36px;
                  font-family: Medium;
                  margin-bottom: 0;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;

                  span {
                    color: $brandSecColor;
                  }
                }

                .description {
                  font-size: 16px;
                  overflow: hidden;
                  height: 72px;
                  margin: 12px 0 20px;
                }

                .banner-btn {
                  color: #3D3D3D;
                  border-radius: 2px;
                  width: 120px;
                  height: 40px;
                  @include font(Medium, 13);
                  display: flex;
                  align-items: center;
                  text-transform: uppercase;
                  transition: .2s;

                  svg {
                    path, polygon {
                      transition: .2s;
                    }
                    transform: rotate(180deg);
                    margin-left: 8px;
                    margin-top: -2px;
                  }
                }
              }

              &:hover {
                .banner-btn {
                  color: $brandSecColor;
                }
                svg {
                  path, polygon {
                    fill: $brandSecColor;
                    //stroke: $brandSecColor;
                  }
                }
              }

              @media all and (max-width: 600px) {
                //padding-top: 40%;
                img {
                  border-radius: 2px;
                }
              }
            }
          }
        }

        @media all and (max-width: 1500px) {
          .banner-content {

            .banner-item {
              .image-wrapper {
                .inner-side {
                  .title {
                    font-size: 30px;
                    margin-bottom: 0;
                  }

                  .description {
                    font-size: unset;
                    height: 60px;
                    margin: 20px 0;
                  }

                  .banner-btn {
                    width: 90px;
                    height: 32px;
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }

        @media all and (max-width: 1200px) {
          .banner-content {

            .banner-item {
              .image-wrapper {
                .inner-side {
                  width: 72%;
                  .title {
                    font-size: 24px;
                    margin-bottom: 0;
                  }

                  .description {
                    font-size: 13px;
                    height: 50px;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: 1000px) {
          .banner-content {
            flex-direction: column;

            .banner-item {
              .image-wrapper {
                padding-top: 250px;
                overflow: hidden;

                img {
                  height: 250px;
                  left: unset;
                  width: unset;
                  right: 0;
                }

                .inner-side {
                  margin-top: 2%;
                  height: 90%;
                  .description {
                    height: 70px;
                    margin: 10px 0;
                  }
                }

              }
            }
          }
        }
        @media screen and (max-width: 900px) {
          .banner-content {
            flex-direction: column;

            .banner-item {
              width: 100%;
              margin-bottom: 10px;
            }
          }
        }
        @media screen and (max-width: 680px) {
          .banner-content {
            flex-direction: column;

            .banner-item {
              .image-wrapper {
                padding-top: 380px;

                img {
                  height: 380px;
                }

                .inner-side {
                  margin-top: 2%;
                  height: 90%;
                  width: 90%;
                  justify-content: unset;
                  .description {
                    height: 70px;
                    margin: 30px 0;
                  }
                }
              }
            }
          }
        }


        @media all and (max-width: 600px) {
          .banner-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0 20px;

            .title {
              font-size: 20px;
            }

            .view-more {
              width: 70px;
              height: 26px;
              font-size: 12px;
            }

          }
        }

        @media all and (max-width: 450px) {
          .banner-content {

            .banner-item {
              .image-wrapper {
                .inner-side {
                  //width: 45%;

                  .title {
                    font-size: 16px;
                    margin-bottom: 0;
                  }

                  .description {
                    font-size: 12px;
                    height: 38px;
                    margin-bottom: 6px;
                  }
                }
              }
            }
          }
        }
    }

    .products-header-part {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 16px 0 0;
      width: 100%;
      overflow: hidden;

      .products-header {
        @include font(Medium, 32);
        color: $darkTextColor;
        width: 100%;


        .rout {
          display: flex;
          margin-bottom: 30px;

          .rout-item {
            font-family: Regular;
            margin-right: 10px;

            a {
              display: flex;
              align-items: center;
              font-size: 15px;
              color: #B1B1B3;
              transition: .3s;
              white-space: nowrap;

              &:hover {
                color: $brandColor;
              }
            }

            svg {
              margin-right: 10px;
              width: 5px;

              path {
                fill: #797b7f;
              }
            }

            @media all and (max-width: 650px) {
              margin-right: 6px;
              a {
                font-size: 13px;
              }
              svg {
                margin-right: 6px;
              }
            }
          }
        }

        .category-sub-title-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          .category-sub-title {
            font-size: 28px;
            font-family: Bold;
            color: black;
            letter-spacing: 1px;
            margin-right: 20px;
            margin-bottom: 12px;
          }

          .products-count {
            color: $brandSecColor;
            @include font(Medium, 14);
          }
        }
      }
    }
  }

  .down-part {
    display: flex;
    position: relative;
    min-width: 400px;

    .filters-part {
      width: 350px;
      margin-top: 16px;
      border: 1px solid $borderColor;
      border-top: 0;
      @media screen and (max-width: 1500px) {
        width: 300px;
      }

      .filters-inner {
        position: sticky;
        top: 106px;
        //z-index: 100;
        width: 100%;
        overflow-y: auto;
        border-top: 1px solid $borderColor;
        //border-radius: 6px 0 0 6px;

        .scrolled-part {
          overflow-y: auto;
          max-height: calc(100vh - 110px);
          @include custom-scrollbar-y;
          .ant-collapse-content{
            @include custom-scrollbar-y;
          }
          .subCategories {
            margin-bottom: 30px;

            .active {
              display: inline-block;
              width: 10px;
              height: 10px;
              background-color: $brandColor;
              border-radius: 50%;
              margin-right: 8px;
            }

            .subCategories-header {
              font-size: 20px;
              font-family: Bold;
              color: #2B2C2D;
              margin-bottom: 20px;

              .active-category {
                display: flex;
                align-items: center;
              }
            }

            .subCategories-items {
              border-radius: 4px;
              margin: 8px 0;
              padding: 16px 12px;
              max-height: 300px;
              overflow: auto;
              box-shadow: 0 2px 4px 0 rgba(47, 51, 56, 0.16), inset 0 0 0 15px #fff;

              .active {
                margin-left: 8px;
              }

              .ant-checkbox-group {
                @include font(Medium, 16);
                color: $darkTextColor;
                padding: 4px 0;
                cursor: pointer;
                display: grid;

                .ant-checkbox-group-item {
                  display: block;
                  margin-right: 0;

                  &:not(:last-child) {
                    margin-bottom: 10px;
                  }
                }
              }

              &::-webkit-scrollbar-track {
                border-radius: 4px;
                background-color: $greyBorderColor;
              }

              &::-webkit-scrollbar {
                width: 6px;
                background-color: #F5F5F5;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background-color: darkgrey;
              }
            }
          }

          .clear-filters {
            margin-top: 20px;

            .PrimaryButton {
              background-color: $brandColor;
              width: calc(100% - 24px);
              margin: 0 auto;
              height: 40px;
              font-size: 15px;
            }
          }
        }
      }
    }

    .products-part {
      border: 1px solid $borderColor;
      border-top: 0;
      border-left: 0;
      margin-top: 16px;
      width: calc(100% - 300px);
      display: flex;
      flex-direction: column;

      .products-top-filters {
        position: sticky;
        top: 106px;
        z-index: 100;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 6px 20px;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;

        .sort-part {
          display: flex;
          align-items: center;

          .sort-default-text {
            height: 34px;
            background-color: #EFF0F4;
            color: #9697a1;
            display: flex;
            align-items: center;
            padding-left: 8px;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            font-size: 13px;
          }

          .custom-input-group {
            width: 160px;
            margin-bottom: 0;

            input {
              height: 34px;
              &::placeholder {
                color: black;
              }
            }

            .ant-select-selector {
              background-color: #EFF0F4;
              border: none !important;
              height: 34px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              padding-left: 5px;

              .ant-select-selection-item {
                line-height: 34px;
                font-size: 13px;
              }
            }

            .ant-select-arrow {
              height: 34px;
            }

            .ant-select-item {
              color: black;
              transition: .2s;
              font-size: 13px;
              &:hover {
                background-color: transparent;
                color: $brandColor;
                svg {
                  polygon {
                    fill: $brandColor;
                  }
                }
              }
            }

            .ant-select-selection-item, .ant-select-selection-placeholder {
              color: black;
            }
            .ant-select-item-option-selected {
              color: $brandColor;
              font-weight: 500;
              svg {
                polygon {
                  fill: $brandColor;
                }
              }
            }
          }
          svg {
            polygon {
              transition: .2s;
            }

            &.rotate {
              transform: rotate(180deg);
            }
          }

          .sort-item {
            margin-right: 20px;
            transition: .2s;
            font-family: Medium;
            cursor: pointer;

            &:hover {
              color: $brandColor;
            }

            &:hover {
              color: $brandColor;

              svg {
                polygon {
                  fill: $brandColor;
                }
              }
            }
          }
        }

        .toggle-track-view {
          display: flex;
          margin-left: 20px;

          .PrimaryButton {
            height: unset;
            width: unset;
            padding: 0 4px;
          }

          .list-view-button {
            background: none;
            border: 1px solid transparent;

            .title {
              display: flex;
            }

            &.selected {
              svg {
                path {
                  fill: $brandColor;
                }
              }
            }
          }
        }
      }

      .products-list {
      }
    }
  }

  @media all and (max-width: 900px) {
    .down-part {
      flex-direction: column;
      .filters-part {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 0;
      }

      .products-part {
        width: 100% ;
        border-left: 1px solid $borderColor;
        margin-top: 0;

        .products-top-filters {
          .toggle-track-view {
            display: flex;
            margin-left: 10px;

            .link-button {
              padding: 0 4px;
              margin-left: 4px;
            }
          }
        }

        .products-list {
          .products-list-wrapper {
            padding: 0;
          }
        }
      }
    }
  }
  @media all and (max-width: 1000px) {
    margin-top: 96px;
    .down-part {
      .filters-part {
        .filters-inner {
          top: 96px;
        }
      }

      .products-part {

        .products-top-filters {
          top: 96px;
        }
      }
    }
  }
  @media all and (max-width: 900px) {
    .up-part {
      .products-header-part {
        .products-header {
          @include font(Medium, 24);
        }
      }
    }
    .down-part {
      .products-part {
        .products-top-filters {
          padding: 10px 12px;
        }
      }
    }
  }
  @media all and (max-width: 700px) {
    .up-part {
      .products-header-part {
        .sort-part {
          > span {
            @include font(Medium, 15)
          }
        }
      }
    }
    .down-part {
      min-width: unset;

      .filters-part {
        .filters-inner {
          .scrolled-part {
            .clear-filters {

              .PrimaryButton {
                width: calc(100% - 32px);
              }
            }
          }
        }
      }
      .products-part {
        .products-top-filters {
          justify-content: space-between;
          padding: 6px 14px;

          .sort-part {
            .sort-default-text {
              display: none;
            }
            .custom-input-group {
              .ant-select-selector {
                padding-left: 12px;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border: 1px solid $borderColor;

  }
}
