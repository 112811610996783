@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.help-page-wrapper {
  width: 100%;

  .help-info-section {
    width: $contentWidth;
    margin: 80px auto 140px auto;
    display: flex;
    justify-content: space-between;
    border-top: 0.5px solid white;

    .image-wrapper {
      width: 44%;

      .image-proportion {
        width: 100%;
        padding-top: 59.8%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 1px;
          border-radius: 8px;
          object-fit: cover;
        }
      }
    }

    .help-info-content {
      width: 44%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .help-info-title {
        font-size: 36px;
        line-height: 50px;
        font-family: Medium;
        margin-bottom: 30px;
      }

      .help-info-description {
        font-size: 18px;
        font-family: Regular;
        color: black;
        overflow: hidden;
        margin-bottom: 30px;
      }

      .PrimaryButton {
        width: 160px;
        margin-top: 30px;
      }
    }

    &.second {
      .image-wrapper {
        .image-proportion {
          padding-top: 73.7%;
        }
      }
    }
  }

  .help-footer-wrapper {
    width: 100%;
    height: auto;
    background-color: #f2f3f5;
    padding-top: 40px;

    .help-footer-title {
      font-size: 36px;
      font-family: Bold;
      text-align: center;
      margin-bottom: 20px;

      span {
        color: $brandSecColor;
      }
    }

    .help-footer-description {
      width: 60%;
      margin: auto;
      font-size: 18px;
      text-align: center;
      //font-family: Medium;
    }

    .PrimaryButton {
      margin: 30px auto;
      //border-radius: 0;
    }

    .img-wrapper {
      margin: auto;
      display: block;
      max-width: 90%;

      img {
      }
    }
  }

  @media all and (max-width: 1000px) {
    margin-top: 96px;

    .help-info-section {
      flex-direction: column;
      padding: $mainPadding;
      margin: 20px auto;

      .image-wrapper {
        width: 100%;
        margin-bottom: 50px;
      }

      .help-info-content {
        width: 100%;
      }

      &.second {
        flex-direction: column-reverse;
      }
    }
  }

  @media all and (max-width: 700px) {

    .help-info-section {
      flex-direction: column;
      padding: $mainPadding 0;

      .image-wrapper {
        width: 100%;
        margin-bottom: 30px;
      }

      .help-info-content {
        width: 100%;

        .help-info-title {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 20px;
        }

        .help-info-description {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }

    .help-footer-wrapper {
      padding-top: 30px;

      .help-footer-title {
        font-size: 26px;
        margin-bottom: 20px;
      }

      .help-footer-description {
        width: 80%;
        font-size: 16px;
      }

      .PrimaryButton {
        margin: 30px auto;
      }
    }
  }

  @media all and (max-width: 500px) {

    .help-footer-wrapper {
      padding-top: 20px;

      .help-footer-title {
        font-size: 26px;
        margin-bottom: 20px;
      }

      .help-footer-description {
        width: 90%;
        font-size: 14px;
      }

      .PrimaryButton {
        margin: 20px auto;
      }
    }
  }
}