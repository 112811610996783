@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";

.order-card-wrapper {
  border-top: 1px solid $borderColor;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;

  &.current-card {
    background-color: #F5F9F9;
  }

  .left-info {
    .info-item {
      color: #a7a7a7;
      font-size: 13px;
      span {
        color: black;
        @include font(Medium, 14);
        font-family: Medium;
      }
    }
  }
  .right-status {
    min-width: 100px;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    font-size: 13px;
    border-radius: 2px;
  }
}
