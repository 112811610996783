@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.plans-details-wrapper {
  .plans-content {

    .plans-details-item {
      width: calc(50% - 6px);
      &:nth-child(n+3) {
        margin-top: 12px;
      }

      .inner-side {
        padding: 6% 0 0 4%;
        display: flex;
        flex-direction: row;
        min-height: 150px;

        .left-side {
          padding-bottom: 6%;
          .description {
            ul {
              display: block;
              list-style-type: disc;
              -webkit-padding-start: 20px;
              margin-top: 12px;

              li {
                margin-bottom: 8px;
              }
            }
          }
        }

        .right-side {
          width: 30%;
          display: flex;
          align-items: flex-end;
          img {
            width: 100%;
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
  }

  @media all and (max-width: 800px) {
    .plans-content {
      .plans-details-item {
        width: 100%;
        &:nth-child(n) {
          margin-top: 12px;
        }
      }
    }
  }

  @media (max-width: 700px) {
     .plans-content .plans-details-item {
      width: 100%;
       margin-bottom: 0;
    }
  }
}