@import 'mainVariables';
@import 'mixins';

.LayoutWrapper {
  width: 100%;
  //height: 100%;
  height: fit-content;
  position: relative;

  .MainContent {
    width: $contentWidth;
    margin: 0 auto;
    padding: 0 0 60px;
    min-height: calc(100vh - 44px);
    @media all and (max-width: 1000px) {
      padding-top: 96px
    }
    @media all and (max-width: 480px) {
      padding: 96px $mainPadding 40px;
    }
  }
  &.bg-dark{
    background-color: #f2f2f2;
  }
  &.mobileMenuOpen {
    overflow: hidden;
  }
  &.hidden-header {
    .MainContent {
      min-height: 100vh;
      padding-bottom: 0;
      padding-top: 0;
      display: flex;
      align-items: center;
      .auth-wrapper {
        align-items: center;
        height: 100%;
        margin: 0;
      }
    }
  }
  @media all and (max-width: 1000px) {
    .MainContent {
      padding: 0 12px 60px;
    }
  }
}
