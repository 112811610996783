@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.rate-page-wrapper {
  width: 100%;
  border: 1px solid $borderColor;
  border-radius: 6px;

  .rate-header {
    border-bottom: 1px solid $borderColor;
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    font-family: Bold;

    .product-part {
      padding: 20px;
      .product-part-desktop-text{
        display: block;
      }
      .product-part-mobile-text{
        display: none;
      }
    }
  }

  .rate-item {
    border-bottom: 1px solid $borderColor;
    display: flex;
    justify-content: space-between;
  }

  .product-part {
    width: 65%;
    height: 100%;
    border-right: 1px solid $borderColor;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .product-image {
      width: 100px;
      height: 100px;
    }

    .product-content {
      width: calc(100% - 120px);

      .product-title {
        width: 40%;
        font-family: Bold;
        font-size: 18px;
        height: 56px;
        overflow: hidden;
      }

      .product-price {
        font-family: Bold;
        font-size: 18px;
      }
    }
  }

  .rate-part {
    width: 35%;
    height: 100%;
    padding: 20px;

    .rate-description {
      color: #a7a7a7;
      margin-right: 12px;
    }

    .rete-point {
      font-size: 22px;
      font-family: Medium;
      margin-right: 12px;
    }

    .rated {
      display: flex;
      align-items: center;
      height: 50px;

      .star-icon {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }

    .rating {
      display: flex;
      align-items: center;
      height: 50px;

      .empty-star-icon {
        path {
          fill: $borderColor;
        }
      }
    }

    .star-icon {
      width: 26px;
      height: 26px;
      margin-right: 16px;
    }
  }

  .PrimaryButton {
    width: calc(35% - 40px);
    margin: 40px 20px 40px auto;

  }

  @media all and (max-width: 1400px) {
    .rate-header {
      font-size: 28px;
    }

    .product-part {

      .product-content {

        .product-title {
          width: 60%;
        }
      }
    }

    .rate-part {

      .rate-description {
        margin-right: 8px;
      }

      .rete-point {
        margin-right: 8px;
      }

      .rated {
        height: 40px;

        .star-icon {
          width: 18px;
          height: 18px;
          margin-right: 12px;
        }
      }

      .rating {
        height: 40px;
      }

      .star-icon {
        width: 22px;
        height: 22px;
        margin-right: 12px;
      }
    }
  }

  @media all and (max-width: 1000px) {
    margin-top: 96px;
    .rate-header {
      font-size: 22px;
      flex-direction: column;

      .product-part {
        padding: 20px;
        border-bottom: 0;
      }
      .rate-part{
        display: none;
      }
    }

    .rate-item {
      flex-direction: column;
    }

    .product-part {
      width: 100%;
      height: 100%;
      border-right: 0;
      border-bottom: 1px solid $borderColor;

      .product-content {

        .product-title {
          width: 60%;
        }
      }
    }

    .rate-part {
      width: 100%;
      height: 100%;
      padding: 20px;

      .rate-description {
        margin-right: 8px;
      }

      .rete-point {
        margin-right: 8px;
      }

      .rated {
        height: 40px;
        .star-icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }

      .rating {
        height: 40px;
      }

      .star-icon {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }
  }

  @media all and (max-width: 700px) {
    .rate-header {
      font-size: 22px;
      flex-direction: column;

      .product-part {
        padding: 20px;
        .product-part-desktop-text{
          display: none;
        }
        .product-part-mobile-text{
          display: block;
        }
      }
    }

    .rate-item {
    }

    .product-part {
      padding: 10px 0;
      border-right: 0;
      border-bottom: 1px solid $borderColor;

      .product-image {
        width: 80px;
        height: 80px;
      }

      .product-content {
        width: calc(100% - 100px);

        .product-title {
          width: 100%;
          font-size: 16px;
          height: 46px;
          line-height: 22px;
        }

        .product-price {
          font-size: 16px;
        }
      }
    }

    .rate-part {
      padding: 10px;

      .rate-description {
        font-size: 12px;
      }

      .rete-point {
        font-size: 16px;
      }

      .rated {
        height: 30px;
      }

      .rating {
        height: 30px;
      }

      .star-icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }

    .PrimaryButton {
      width: calc(100% - 20px);
      margin: 10px;

    }
  }

  @media all and (max-width: 500px) {
  }
}