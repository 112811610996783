@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.about-page-wrapper {
  width: 100%;

  .contacts-info-section {
    width: $contentWidth;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    border-top: 0.5px solid white;

    .image-wrapper {
      width: 46%;

      .image-proportion {
        width: 100%;
        padding-top: 98%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 1px;
          border-radius: 8px;
          object-fit: cover;
        }
      }
    }

    .contacts-info-content {
      width: 46%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .contacts-info-title {
        font-size: 36px;
        line-height: 50px;
        font-family: Medium;
        margin-bottom: 30px;
      }

      .contacts-info-description {
        font-size: 18px;
        font-family: Regular;
        color: black;
        overflow: hidden;
        margin-bottom: 30px;
      }

      .PrimaryButton {
        width: 160px;
        margin-top: 30px;
      }
    }
  }

  .contact-section {
    width: $contentWidth;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .map-wrapper {
      width: 66%;
      height: auto;

      .map {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }

    .form-wrapper {
      width: 30%;
      height: 100%;
      //display: flex;
      //justify-content: center;
      //flex-direction: column;
      //flex-wrap: wrap;

      .form-title {
        font-size: 26px;
        line-height: 30px;
        font-family: Bold;
      }

      .form-description {
        font-size: 12px;
        margin-bottom: 20px;
      }

      .custom-input-group {
        width: 100%;
        margin-bottom: 12px;

        input, textarea {
          &:not(.ant-select-search__field):focus {
            border: 1px solid $brandColor;
            outline: none;
            background-color: #f6f9f9;
          }

          &.invalid {
            border-color: #FF0000 !important;
            outline: none;
          }
        }
      }

      .PrimaryButton {
        margin-left: auto;
        //background-color: $brandSecColor;
        width: 100%;
        //font-size: 16px;
        .anticon-loading {
          margin-left: 10px;
        }

        .tick-icon {
          width: 20px;
          height: 20px;
          transition: all 0.2s;
          visibility: visible;

          polygon {
            fill: white;
          }
        }

        .show-tick {
          width: 20px;
          visibility: visible;
          opacity: 1;
          margin-left: 10px;
        }

        .hide-tick {
          width: 0;
          visibility: hidden;
          opacity: 0;
          //width: 0;
        }

        &:hover {
          box-shadow: 2px 0px 10px 0px rgba(113, 113, 113, 0.14);

          .tick-icon {
            path {
              fill: $brandColor;
            }
          }
        }
      }

      .message {
        .custom-input-group {
          width: 100%;
        }
      }
    }
  }

  .full-section {
    width: 100%;
    background-color: $brandColor;
    margin: 80px 0;
    padding: 100px calc((100vw - #{$contentWidth}) / 2);
    color: white;

    .section-title {
      width: 62%;
      font-size: 40px;
      line-height: 60px;
      font-family: Medium;
      margin-bottom: 20px;
    }

    .section-description {
      width: 50%;
      font-size: 20px;
      line-height: 32px;
      font-family: Regular;
    }

    .section-items {
      display: flex;
      justify-content: space-between;
      margin-top: 100px;

      .about-item {
        width: 26%;

        .item-title {
          font-size: 28px;
          line-height: 32px;
          font-family: Medium;
          margin-bottom: 20px;
        }

        .item-description {
          font-size: 15px;
          line-height: 24px;
        }

        &:nth-child(1) {
          //border-right: 1px solid white;
          //border-left: 1px solid white;
        }

        &:nth-child(2) {
          //width: 40%;
          //border-right: 1px solid white;
          //border-left: 1px solid white;
          //padding-left: 5%;
        }
      }

      .line {
        width: 1px;
        height: auto;
        background-color: #6ea6a6;
      }
    }

    &:nth-child(1) {
      padding: 60px calc((100vw - #{$contentWidth}) / 2);
    }
  }

  .contact-items-section {
    width: 100%;
    margin-bottom: 40px;
  }

  .contact-card-list {
    width: $contentWidth;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 10;
    border-radius: 6px;
    margin: 60px auto;

    .contact-item {
      width: 25%;
      display: flex;
      align-items: center;
      padding: 20px 24px;
      transition: all 0.2s;
      border-radius: 6px;

      .icon-wrapper {
        background-color: #f3f4f6;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 20px;
        @include flex-center;

        svg {
          path {
            fill: $brandColor;
          }
        }
      }

      .contact-item-map {
        cursor: pointer;
      }

      .item-content {
        //white-space: nowrap;
        width: calc(100% - 80px);

        .title {
          font-family: Medium;
          font-size: 18px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          //margin-bottom: 10px;
        }

        .value {
          font-size: 13px;
          color: #828a9c;
          //white-space: nowrap;
          //text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
      }

      &:hover {
        background-color: $brandColor;

        .icon-wrapper {
          background-color: white;

          svg {
            path {
              fill: $brandColor;
            }
          }
        }

        .item-content {

          .title {
            color: white;
          }

          .value {
            color: white;
          }

        }
      }
    }
  }

  @media all and (max-width: 1260px) {
    .contact-card-list {
      padding: 0 $mainPadding;

      .contact-item {
        width: calc(50% - 10px);
        margin: 0px 0 20px 0;
        padding: 16px 20px;
        background-color: $brandColor;
        color: white;

        .item-content {
          .value {
            color: white;
          }
        }
      }
    }
    .full-section {
      padding: 60px calc((100vw - var(--contentWidth)) / 2);;
    }
  }
  @media all and (max-width: 1000px) {
    margin-top: 96px;
    padding: 0 12px;

    .contacts-info-section {
      flex-direction: column;
      padding: $mainPadding;
      margin-top: 20px;

      .image-wrapper {
        width: 100%;
        margin-bottom: 30px;
      }

      .contacts-info-content {
        width: 100%;
        .PrimaryButton {
          margin-top: 0;
        }
      }
    }

    .full-section {
      padding: $mainPadding;
      margin: 30px 0;

      .section-title {
        width: 70%;
        font-size: 30px;
        line-height: 50px;
        margin-bottom: 20px;
      }

      .section-description {
        width: 60%;
        font-size: 16px;
        line-height: 26px;
      }

      .section-items {
        display: flex;
        justify-content: unset;
        flex-direction: column;
        margin-top: 40px;

        .about-item {
          width: 100%;

          .item-title {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 10px;
          }

          .item-description {
            font-size: 15px;
            line-height: 24px;
          }
        }

        .line {
          width: 20%;
          height: 1px;
          background-color: #6ea6a6;
          margin: 20px 0;
        }
      }

      &:nth-child(1) {
        padding: 60px calc((100vw - #{$contentWidth}) / 2);
      }
    }

    .contact-section {
      padding: $mainPadding;

      //flex-direction: column-reverse;

      .map-wrapper {
        width: 62%;
      }

      .form-wrapper {
        width: 34%;
      }
    }
  }

  @media all and (max-width: 700px) {
    .contact-card-list {
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      margin: 20px 0;

      .contact-item {
        width: 100%;

        .icon-wrapper {
          width: 40px;
          height: 40px;
          margin-right: 20px;
          @include flex-center;
        }
      }
    }
    .contacts-info-section {
      flex-direction: column;
      padding: $mainPadding 0;

      .image-wrapper {
        width: 100%;
        margin-bottom: 30px;
      }

      .contacts-info-content {
        width: 100%;

        .contacts-info-title {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 20px;
        }

        .contacts-info-description {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }

    .full-section {
      .section-title {
        width: 70%;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 10px;
      }

      .section-description {
        width: 60%;
        font-size: 14px;
        line-height: 20px;
      }

      .section-items {
        margin-top: 30px;

        .about-item {
          width: 100%;

          .item-title {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 10px;
          }

          .item-description {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }

      &:nth-child(1) {
        padding: 60px calc((100vw - #{$contentWidth}) / 2);
      }
    }

    .contact-section {
      flex-direction: column-reverse;

      .map-wrapper {
        width: 100%;
        height: 300px;
        margin-top: 20px;
      }

      .form-wrapper {
        width: 100%;
      }
    }
  }
}

.marker{
  .marker-icon {
    background-image: url("../../assets/images/icons/ic_marker.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 30px;
    height: 50px;
  }
}