@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.home-categories-wrapper {
  padding: 20px 0 0;
  margin-bottom: 50px;
  position: relative;
  top: 30px;

  .categories-header {
    width: 100%;
    display: flex;
    padding: 0 3px 0 0;

    .categories-title {
      font-size: 28px;
      font-family: Medium;
      color: black;
      letter-spacing: 1px;
    }
  }

  .categories-list-wrapper {

    //width: calc(100%);
    //padding: 0 10px;
    ////padding-bottom: 10px;
    width: calc(100% + 16px);
    overflow: hidden;

    .slick-list {
      overflow: visible;

      .slick-active {
        .category-item-card {
          width: calc(100% - 16px) ;
          //padding: 20px;
          padding-right: 2px;
          //margin-bottom: 80px;
          margin-top: 20px;

          .category-item-card-content {
            padding-top: 100%;
            position: relative;
            //box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05);

            .category-item {
              background-color: #F2F3F5;
              img {
                transition: .3s;
              }
            }

            &:hover {
              .category-item {
                z-index: 1000;

                img {
                  transform: scale(1.1);
                }
                .item-title {
                  color: $brandColor;
                }
              }
            }
          }
        }
      }
    }

    .category-item-card {
      width: calc(100% - 10px) !important;
      //padding: 20px;
      padding-right: 2px;
      //margin-bottom: 80px;
      margin-top: 20px;

      .category-item-card-content {
        padding-top: 100%;
        position: relative;
        //box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05);

        .category-item {
          background-color: white;
          border-radius: 6px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 0 24px;
          justify-content: unset;
          //transition: all 0.3s;


          img {
            width: 84px;
            height: 84px;
            margin-top: calc(100% - 116px);
            object-fit: contain;
          }

          .item-title {
            @include font(Medium, 18);
            color: $blackTextColor;
            width: 100%;
            height: 32px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            margin-top: 30px;
            transition: .2s;
          }

          .sub-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          }

          .category-sub-items {
            //display: flex;
            align-items: center;
            overflow: hidden;
            width: 100%;
            color: $brandColor;
            //visibility: hidden;
            display: none;
            height: 40px;

            .sub-category-item {
              white-space: nowrap;
              margin-right: 10px;
            }
          }

          .see-more {
            text-align: center;
            color: $brandColor;
            //visibility: hidden;
            display: none;
            //height: 0;
          }
        }


      }
    }

    &:nth-child(5n) {
      .category-item-card {
        padding-right: 20px;
      }
    }
  }

  @media all and (max-width: 1500px) {
    .categories-list-wrapper {
      .category-item-card {
        width: calc(100% / 4);
      }
    }
  }

  @media all and (max-width: 1260px) {
    top: 20px;
    .categories-list-wrapper {

      .category-item-card {

        .category-item-card-content {

          .category-item {

            //img {
            //  width: 140px;
            //  height: 140px;
            //  margin-top: calc(100% - 200px);
            //}

            .item-title {
              font-size: 14px;
              height: 22px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    .categories-list-wrapper {
      width: calc(100% + 12px);
      //padding: 0 10px;
      //margin-left: -10px;

      .category-item-card {
        //margin-bottom: 50px;
        //width: calc(100% / 4);
        //padding-right: 10px;
        //margin-bottom: 10px;

        .category-item-card-content {

          .category-item {

            //img {
            //  width: 110px;
            //  height: 110px;
            //  margin-top: calc(100% - 160px);
            //}

            .item-title {
              font-size: 14px;
              height: 22px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 850px) {
    .categories-list-wrapper {


      .category-item-card {
        width: calc(100% / 3);

        .category-item-card-content {

          .category-item {

            .item-title {
              font-size: 14px;
              height: 22px;
            }

            .category-sub-items {
              height: 40px;
              font-size: 12px;

              .sub-category-item {
                margin-right: 6px;
              }
            }

            .see-more {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    top: 0;
    margin-bottom: 16px;
    .categories-header {
      .categories-title {
        margin: 0;
        font-size: 20px;
      }

      .rout {
        .rout-item {
          margin-right: 6px;

          a {
            font-size: 13px;
            @media all and (max-width: 400px) {
              font-size: 12px;
            }
          }

          svg {
            margin-left: 6px;
            width: 5px;
          }
        }
      }
    }
    .categories-list-wrapper {
      width: calc(100% + 12px);
      padding: 0;
      margin-left: 0;

      .category-item-card {
        width: calc(100% / 2);

        .category-item-card-content {

          .category-item {
            padding: 5px;
            justify-content: center;

            img {
              //width: 120px;
              //height: 120px;
              margin-top: calc(100% - 200px);
            }

            .item-title {
              font-size: 12px;
              height: 22px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 400px) {
    .categories-list-wrapper {

      .category-item-card {
        width: calc(100% / 2);

        .category-item-card-content {

          .category-item {
            padding: 5px;
            justify-content: center;

            img {
              width: 70px;
              height: 70px;
              margin-top: calc(100% - 140px);
            }

            .item-title {
              margin-top: 20px;
              font-size: 12px;
              height: 22px;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 340px) {
    .categories-list-wrapper {

      .category-item-card {
        width: 100%;

        .category-item-card-content {

          .category-item {
            padding: 5px;
            justify-content: center;

            img {
              width: 130px;
              height: 130px;
              margin-top: calc(100% - 300px);
            }

            .item-title {
              margin-top: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
