@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.banners-wrapper {
  width: 320px;

  .banners-title {
    display: none;
  }

  .image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .slick-slider {
      height: 100%;

      .slick-list {
        height: 100%;
        border-radius: 6px;

        .slick-track {
          height: 100%;
          border-radius: 6px;
          margin: 0;


          .slick-slide {
            height: 100%;
            border-radius: 6px;
            padding-right: 4px;

            > div {
              height: 100%;
              border-radius: 6px;
              > div {
                height: 100%;
              }
            }
          }
        }
      }

      .slick-dots {
        display: flex;
        justify-content: center;
        list-style-type: none;

        li {
          margin: 0 6px;
          width: 30px;
          height: 2px;
        }

        button {
          display: block;
          width: 100%;
          height: 100%;
          padding: 0;
          border: none;
          border-radius: 10px;
          background-color: #BEBEC3;

          text-indent: -9999px;
        }

        li.slick-active button {
          background-color: $brandColor;
        }
      }

      .slider-item {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    .arrows {
      transition: .2s;
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      .arrows {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  @media all and (max-width: 1500px) {
    width: 280px;

  }
  @media all and (max-width: 1260px) {

    width: calc(100% + 12px);
    margin-top: 70px;

    //.banners-title {
    //  display: block;
    //  font-size: 28px;
    //  font-family: Medium;
    //  color: black;
    //  letter-spacing: 1px;
    //}

    .slick-dots {
      display: none !important;
    }

    .image-wrapper {

      .arrows {
        .next-btn {
          right: -7px;
        }
      }

      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              padding-right: 12px;
            }
          }
        }
      }
    }

  }

  @media all and (max-width: 800px) {
    .image-wrapper {
      .slick-slider {

        .slider-item {

          .slider-info-wrapper {

            .slide-title {
              font-size: 16px;
              line-height: 20px;
              height: 48px;
            }

            .slide-sub-title {
              @include font(Medium, 13);
              width: 40%;
              height: 58px;
            }

            .link-button {
              width: 90px;
              height: 35px;
              font-size: 11px;
            }
          }
        }
      }

      .arrow-wrapper {
        bottom: 6px;

        .prev_btn {
          margin-right: 6px;
        }

        svg {
          width: 24px;
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .image-wrapper {
      width: 100%;
      position: relative;

      .main-slider-items {
        padding-top: 149.3%;
      }

      .slick-slider {

        .slider-item {
          padding-top: 149.3%;

          .slider-info-wrapper {
            align-items: flex-start;

            .info-content {
              padding-top: 8%;
              height: unset;
              max-height: 35%;
            }

            .slide-title {
              font-size: 16px;
              line-height: 20px;
              height: unset;
              max-height: 58px;
              width: 90%;
              margin-bottom: 14px;
            }

            .slide-sub-title {
              @include font(Medium, 13);
              width: 85%;
              height: unset;
              max-height: 60px;
              margin-bottom: 14px;
            }

            .link-button {
              width: 90px;
              height: 35px;
              font-size: 11px;
            }
          }
        }
      }

      .arrows {
        bottom: 6px;

        .prev-btn {
          right: 4px;
        }

        .next-btn {
          right: 2px;
        }
      }
    }
  }


  @media all and (max-width: 400px) {
    .image-wrapper {
      .slick-slider {

        .slider-item {

          .slider-info-wrapper {


            .slide-title {
              font-size: 14px;
              line-height: 18px;
              height: 40px;
            }

            .slide-sub-title {
              font-size: 11px;
              line-height: 13px;
              height: 28px;
            }

            .link-button {
              width: 80px;
              height: 26px;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
