@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.plans-wrapper {
  margin-bottom: 30px;

  .plans-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 30px 0;

    .title {
      font-size: 28px;
      font-family: Medium;
      color: black;
      letter-spacing: 1px;
    }

    .view-more {
      height: 35px;
      border-radius: 2px;
      @include flex-center;
      @include font(Medium, 17);
      color: $brandSecColor;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $brandColor;
      }
    }
  }

  .download-app-wrapper {
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    margin-top: 16px;
    border-radius: 6px;
    background-color: #F2F3F4;
    position: relative;

    .app-info-wrapper {
      position: absolute;
      top: 0;
      left: 7.5%;
      color: $blackTextColor;
      height: 100%;
      width: 92.5%;
      display: flex;
      align-items: center;


      .info-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 66%;
        position: relative;


        .app-content {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          height: 66%;
          position: relative;
        }

        .app-title {
          @include font(Bold, 48);
          color: black;
          overflow: hidden;
          line-height: 54px;
          width: 55%;
          height: 110px;

          span {
            color: $brandSecColor;
          }
        }

        .app-description {
          font-size: 16px;
          line-height: 24px;
          width: 50%;
          height: 50px;
          overflow: hidden;
          letter-spacing: 0.23px;
        }

        .qr-wrapper {
          display: flex;
          align-items: center;
          svg, img {
            height: 80px;
            width: 80px;
            &:hover {
              cursor: url("../../../assets/images/icons/ic_cursor_search.svg"),auto;
            }
          }

          .PrimaryButton {
            text-transform: uppercase;
            width: 160px;
            height: $elementMainHeight;
            border-radius: 4px;
            background-color: $brandColor;
            margin-right: 12px;
            margin-bottom: 0;
            &:hover{
              color: white;
            }
          }
        }
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .PrimaryButton {
      @include font(Medium, 18);
      width: calc(50% - 10px);
      height: calc(#{$elementMainHeight} + 16px);
      &.disabled{
        cursor: unset;
        &:hover {
          background-color: $brandColor !important;
        }
      }
    }
  }

  .plans-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .plans-item {
      width: calc(100% / 3 - 10px);
      border-radius: 6px;
      transition: .3s;

      &:nth-child(3n+1) {
        background-color: #F2F3F4;
        background-image: linear-gradient(90deg, #F2F3F4 0%, #EBF3F4 100%);
      }

      &:nth-child(3n+2) {
        background-color: #EBF3F4;
        background-image: linear-gradient(45deg, #EBF3F4 0%, #FFFAFC 100%);
      }

      &:nth-child(3n+3) {
        background-color: #FBF9FA;
        background-image: linear-gradient(45deg, #FBF9FA 0%, #F5F0F2 100%);
      }

      .inner-side {
        height: 100%;
        padding: 4%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .plan-icon {
          width: 36px;
          height: 36px;
          object-fit: contain;
          margin-bottom: 20px;
        }

        .title {
          font-size: 32px;
          font-family: Medium;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 20px;

          .marked-word {
            color: $brandSecColor;
          }

        }

        .plans-btn {
          color: #3D3D3D;
          border-radius: 2px;
          width: 150px;
          height: 40px;
          @include font(Medium, 13);
          display: flex;
          align-items: center;
          text-transform: uppercase;
          transition: .2s;

          svg {
            path, polygon {
              transition: .2s;
            }
            transform: rotate(180deg);
            margin-left: 8px;
            margin-top: -2px;
          }
        }
      }
      &:hover {
        .plans-btn {
          color: $brandSecColor;
        }
        svg {
          path, polygon {
            fill: $brandSecColor;
            //stroke: $brandSecColor;
          }
        }
      }
    }
  }

  @media all and (max-width: 1500px) {
    .plans-content {

      .plans-item {
        .inner-side {
          .title {
            font-size: 26px;
            margin-bottom: 0;
          }
        }
      }
    }
    .download-app-wrapper {

      .app-info-wrapper {

        .info-content {

          .app-title {
            @include font(Bold, 40);
            color: black;
            overflow: hidden;
            line-height: 45px;
            width: 55%;
            height: 90px;
          }

          .app-description {
            line-height: 22px;
            height: 48px;
          }

          .PrimaryButton {
            width: 146px;
            height: 40px;
            border-radius: 4px;
            font-size: 14px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1260px) {
    .plans-content {

      .plans-item {
        .inner-side {
          .title {
            font-size: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
    .download-app-wrapper {

      .app-info-wrapper {

        .info-content {
          height: 75%;

          .app-title {
            @include font(Bold, 32);
            color: black;
            overflow: hidden;
            line-height: 35px;
            width: 55%;
            height: 70px;
          }

          .app-description {
            line-height: 22px;
            height: 42px;
            font-size: 15px;
          }

          .PrimaryButton {
            width: 146px;
            height: 38px;
            border-radius: 4px;
            font-size: 13px;
          }
        }
      }
    }
  }



  @media all and (max-width: 1000px) {
    .buttons-wrapper {
      .PrimaryButton {
        font-size: 16px;
      }
    }
  }

  @media all and (max-width: 900px) {
    .download-app-wrapper {
      padding-top: 60px;
      .app-info-wrapper {

        .info-content {
          height: 80%;
        }
      }
    }
    .buttons-wrapper {
      .PrimaryButton {
        width: calc(50% - 4px);
        font-size: 15px;
      }
    }
  }

  @media all and (max-width: 800px) {
    .buttons-wrapper {
      flex-direction: column;
      .PrimaryButton {
        width: 100%;
        &:last-child {
          margin-top: 12px;
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .plans-content {
      flex-direction: column;

      .plans-item {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .download-app-wrapper {
      padding-top: 100px;
      .app-info-wrapper {

        .info-content {
          height: 80%;
          .app-title {
            width: 70%;
          }
          .app-description {
            width: 60%;
          }
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .plans-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 30px;

      .title {
        font-size: 20px;
      }

      .view-more {
        width: 70px;
        height: 26px;
        font-size: 12px;
      }
    }
    .buttons-wrapper {
      .PrimaryButton {
        height: calc(#{$elementMainHeight} + 6px);
      }
    }
  }
  @media all and (max-width: 450px) {
    .download-app-wrapper {
      padding: 120px 0 60px;
      .app-info-wrapper {

        .info-content {
          height: 80%;
          .app-title {
            width: 100%;
            font-size: 24px;
            line-height: 28px;
            height: 60px;
          }
          .app-description {
            width: 80%;
          }
          .PrimaryButton {
            margin-bottom: 30px;
          }
        }
      }
    }
    .buttons-wrapper {
      .PrimaryButton {
        font-size: 14px;
      }
    }
  }
  @media all and (max-width: 380px) {
    .buttons-wrapper {
      .PrimaryButton {
        font-size: 12px;
      }
    }
  }
}