@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.blog-page-wrapper {
  min-height: calc(100vh - 160px);
  padding-bottom: 80px;

  .blog-list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;

    .blog-card {
      width: calc(92% / 5);
      margin-bottom: 2%;
      background-color: white;
      transition: all 0.5s;
      margin-right: 2%;
      cursor: pointer;

      .image-wrapper {
        width: 100%;
        position: relative;

        .image-proportion {
          padding-bottom: 100%;
          position: relative;
          width: 100%;
          overflow: hidden;
          border-radius: 4px;

          img {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
            z-index: 1;
            transition: .3s;
          }

          .badge {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 10;
            color: white;
            font-size: 13px;
            font-family: Medium;
            padding: 3px 12px;
            border-radius: 2px;
            //text-transform: uppercase;
          }

        }
      }

      .item-info-title {
        width: 100%;
        padding: 20px 0;
        font-size: 16px;
        line-height: 24px;
        height: 66px;
        color: black;
        font-family: Medium;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .date-part {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //padding: 0 20px;
        border-bottom: 1px solid $borderColor;
        margin-top: 10px;
        transition: .3s;
        .date {
          color: #828282;
          font-size: 14px;
          //font-family: Medium;
        }

        .details-btn {
          width: 80px;
          height: 40px;
          //border-radius: 50%;
          //background-color: $brandColor;
          //@include flex-center;
          display: flex;
          align-items: center;
          transition: .3s;
          cursor: pointer;
          font-size: 12px;
          overflow: hidden;
          margin-bottom: -1px;
          border-bottom: 2px solid #3d3d3d;

          .more {
            visibility: hidden;
            width: 0;
            color: white;
            //transition: all .3s;
            text-align: center;
            white-space: nowrap;
          }

          svg {
            position: relative;
            left: 50%;
            transition: .3s;
            visibility: visible;
            transform: rotate(180deg) translateX(50%);

            polygon {
              fill: #3d3d3d;
            }
          }
        }
      }

      &:nth-child(5n) {
        margin-right: 0;
      }

      &:hover {
        .image-wrapper {
          .image-proportion {
            img {
              transform: scale(1.1);
            }
          }
        }

        .date-part {
          .details-btn {
            border-bottom: 2px solid $brandSecColor;
            svg {
              left: 100%;
              transform: rotate(180deg) translateX(100%);
              polygon {
                fill: $brandSecColor;
              }
            }
          }

          //         .details-btn {
          //    border-radius: 50px;
          //    width: 70px;
          //    svg{
          //      width: 0;
          //      visibility: hidden;
          //    }
          //    .more{
          //      width: 100%;
          //      visibility: visible;
          //    }
          //  }
        }
      }
    }

  }

  .ant-tabs {
    margin-top: 60px;

    .ant-tabs-nav:before {
      border-bottom: 1px solid $borderColor;
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          font-size: 18px;
          padding: 10px 10px;

          &.ant-tabs-tab-active {
            border-color: $brandColor;

            &:before {
              border-color: $brandColor;
            }

            .ant-tabs-tab-btn {
              color: $brandColor;
            }
          }

          &:hover {
            color: $brandColor;
          }
        }

        .ant-tabs-ink-bar {
          background: $brandColor;
          height: 3px;
        }
      }
    }

    .ant-tabs-content-holder {
      min-height: 440px;
    }
  }

  @media screen and (max-width: 1700px) {
    .blog-list {
      .blog-card {
        width: calc(94% / 4);

        &:nth-child(5n) {
          margin-right: 2%;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .blog-list {
      .blog-card {
        .item-info-title {
          font-size: 14px;
          line-height: 20px;
          height: 58px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .blog-list {
      .blog-card {
        width: calc(96% / 3);
        margin-bottom: 2%;

        .image-wrapper {
          .image-proportion {
            .badge {
              top: 8px;
              left: 8px;
              font-size: 12px;
              padding: 2px 8px;
            }

          }
        }

        &:nth-child(4n) {
          margin-right: 2%;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .ant-tabs {
      margin-top: 30px;

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
      margin-top: 96px;
  }

  @media screen and (max-width: 700px) {
    .blog-list {
      .blog-card {

        .item-info-title {
          font-size: 13px;
          line-height: 18px;
          height: 56px;
        }

        .date-part {
          height: 30px;

          .date {
            font-size: 11px;
          }

          .details-btn {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .blog-list {
      .blog-card {
        width: calc(97% / 2);
        margin-bottom: 2%;
        margin-right: 2%;

        &:nth-child(3n) {
          margin-right: 2%;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    .blog-list {
      .blog-card {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        .date-part {
          height: 40px;

          .details-btn {
            width: 60px;
            height: 40px;
          }
        }
      }
    }
  }
}