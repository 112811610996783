@import "./assets/styles/antd-styles-overrides";
@import "./assets/styles/mainVariables";
@import "./assets/styles/fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-size: 15px;
  font-family: Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //user-select: none;
}

button {
  cursor: pointer;
  border: none;
  padding: 0;
  user-focus: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    outline: none;
  }
}

img {
  user-focus: none;
  user-select: none;
}

p {
  margin-bottom: 0;
}

a, a:hover {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10101;
  overflow: hidden;

  .ant-modal {
    width: 100% !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    top: 0;

    .ant-modal-header, .ant-modal-footer, .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {

      position: static;
      border-radius: 8px;
      background-color: transparent;
    }

    .slider-modal-content {
      border-radius: 8px;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;

      .dialog-closing-btn {
        top: 80px;
        width: 40px;
        height: 40px;
        opacity: 0;

        svg {
          width: 100%;
          height: 100%;

          polygon {
            fill: white;
          }
        }

        &.show {
          transition: opacity 0.2s;
          opacity: 1;
        }
      }
    }

  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: auto;
  //border-radius: 4px;
  transform: translate(-50%, -50%);
  //background: white;
  z-index: 9900;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9900;
  bottom: 0;
  background: rgba(0, 0, 0, .35);
  overflow: hidden;
}

.sun-editor-editable{
  ul {
    list-style: unset;
    //padding-left: unset;
    margin-bottom: unset;
  }
  *{
    background-color: transparent!important;
  }
}