@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.category-items-wrapper {
  width: 100%;

  .category-sub-list-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .category-sub-item {
      @include flex-center;
      background-color: $greyBgColor;
      margin-right: 10px;
      margin-bottom: 10px;
      height: 40px;
      border-radius: 4px;
      padding: 0 20px;
      font-family: Regular;
      transition: all 0.2s;
      cursor: pointer;
      color: black;

      .category-name {
        font-size: 16px;
        white-space: nowrap;
        width: 100%;
        max-width: calc(100vw - 50px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .selected-sub-category{
      background-color: $brandColor;
      .category-name{
        color: white;
      }
    }
  }

  @media all and (max-width: 700px) {
    .category-sub-title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .category-sub-list-wrapper {
      .category-sub-item {
        margin-right: 6px;
        margin-bottom: 6px;
        height: 28px;
        padding: 0 10px;

        .category-name {
          font-size: 12px;
        }
      }
    }
  }
}
