@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";


.section-wrapper {
  width: 100%;
  padding-top: 20px;
  position: relative;

  .section-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .section-title {
      font-size: 26px;
      font-family: Medium;
      color: black;
      letter-spacing: 1px;
      margin: 30px 0 0 0;
    }

    .arrows {

      .prev-btn {
        left: -20px
      }
      .next-btn {
        right: -20px;
      }
    }
  }

  .section-list-wrapper {
    //margin: 0 -20px 0 0;
    width: calc(100% + 40px);
    padding: 0px 20px;
    margin-left: -20px;
    overflow: hidden;

    .slick-list {
      overflow: visible;
    }

    .slick-track {
      margin-left: unset;
      padding: 20px 0;

      .slick-active {
        .product-card {
          &:hover:before {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
          }
        }
      }
    }

    .product-card {
      width: 100%;
      //padding-bottom: 0;
      &.product-grid-card {
        border-bottom-color: transparent;
      }
      &:hover:before {
        box-shadow: unset;
      }
    }
  }

  @media all and (max-width: 1000px) {
    .section-header {
      .section-title {
        font-size: 25px;
      }
      .arrows {

        .prev-btn {
          left: -12px
        }
        .next-btn {
          right: -12px;
        }
      }
    }
    .section-list-wrapper {
      margin-right: -10px;
      width: calc(100% + 20px);
      margin-left: -10px;
    }
  }
  @media all and (max-width: 800px) {
    .section-list-wrapper {
      padding: 0 13px;
    }
  }

  @media all and (max-width: 600px) {
    padding-top: 0;
    .section-header {
      margin-bottom: 0;
      .section-title {
        font-size: 20px;
        margin-top: 16px;
      }
    }
  }
  @media all and (max-width: 450px) {
    padding-top: 0;
    .section-header {
      .arrows {
        .next-btn {
          right: -10px;
        }
      }
    }
  }
}


