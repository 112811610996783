@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.custom-input-group {
  position: relative;
  margin-bottom: 24px;

  label {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #202020;
    margin-bottom: 8px;
  }

  .ant-select-selection-item {
    box-shadow: unset;
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }

  input, textarea, select {
    width: 100%;
    height: $elementMainHeight;
    border: 1px solid $borderColor;
    border-radius: 2px;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #202020;

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }


    &:not(.ant-select-search__field):focus {
      outline: none;
      background-color: #f6f9f9;
      //box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3) !important;
      border: 1px solid $brandColor;
      color: #202020;
    }

    &.resizable {
      resize: vertical;
      min-height: 100px;
    }

    &.invalid {
      border: 1px solid #ff0000 !important;
    }

    &:disabled {
      //background: #f8f8f8 !important;
    }
  }

  .phone-input {
    padding-left: 56px;
  }

  .phone-code {
    position: absolute;
    bottom: 12px;
    left: 12px;
    font-size: 14px;
    border-right: 1px solid #bfbfbf;
    padding-right: 6px;
    @media (max-width: 1260px) {
      bottom: 9px;
    }
  }

  input[type="checkbox"] {
    height: 25px;
    width: 25px;
  }

  input::placeholder {
    color: #bfbfbf;
    font-size: 14px;
  }

  textarea {
    resize: none;
    height: 100px;

    &::placeholder {
      font-family: Regular;
      color: #bfbfbf;
      font-size: 15px;
    }
  }

  .custom-select {
    position: relative;

    //&.ant-select-open {
    //  min-width: 150px;
    //}

    .ant-select-selection--multiple {
      min-height: 50px;

      .ant-select-selection__rendered {
        min-height: 50px;
        display: flex;
        align-items: center;

        li {
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }
    }

    .ant-select-selection--single {
      height: 50px;
      display: flex;
      align-items: center;


      .ant-select-selection__rendered {
        width: 100%;
        overflow: hidden;
      }
    }

    .ant-select-selection-search {
      input {

        box-shadow: unset !important;
      }
    }

    &:focus {
      outline: none;
    }

  }

  .tree-select-wrapper {
    width: 100%;
    position: relative;
  }

  .ant-select-dropdown {
    .child-option {
      padding-left: 25px;
    }

    .parent-option {
      font-family: Bold;

      &:hover {
        background-color: transparent;
      }

      &.ant-select-item-option-disabled {
        color: unset;
        cursor: default;
      }
    }
  }

  .ant-select-arrow {
    height: 100%;
    top: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $elementMainHeight;
    width: 20px;

    .custom-suffix-icon {
      transform: none;
      width: 40px;
      height: 40px;
      transition: transform 0.2s;
      transform-origin: center;

      path {
        fill: $greyBorderColor;
      }

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .visibility-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    height: 18px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;

      path {
        fill: #c6c6c6;
      }
    }

    i {
      font-size: 16px;
      user-select: none;
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #10233c;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover input:not(:disabled), &:hover textarea:not(:disabled) {
    border-color: rgba(0, 0, 0, .25);
  }
}


.select-options {
  width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 50;


  .option-item {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    overflow: hidden;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;

    .clear-icon {
    }

    input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #e6f7ff;
    }

    &.checked {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 600;
      background-color: #fafafa;
    }
  }
}

.clear-option {
  border-bottom: 1px solid $greyBorderColor;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
