@import 'mainVariables';

.ant-select {

  &.ant-select-single {
    border: none;
  }

  &.invalid .ant-select-selector {

    border-color: #FF0000;
  }

  .ant-select-selection__placeholder {
    color: #9b9b9b;
    font-size: 13px;
  }

  .ant-select-selection--single {
    height: 35px;

    .ant-select-selection__rendered {
      line-height: 33px;

      &:focus, &:hover, &:active {
        outline: none;
      }
    }
  }

  .ant-select-search input {
    outline: none !important;
    padding: 6px 0;
  }

  &.ant-select-focused {
    .ant-select-selector {
      border: 1px solid $borderColor !important;
      box-shadow: 0 0 0 0 white !important;
    }
  }
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 3px #D0E3F3;

  &:hover {
    border: 1px solid $borderColor !important;
  }
}

.ant-select.ant-select-focused .ant-select-selector {
  border: 1px solid $borderColor;
}

.ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: $elementMainHeight;
  padding: 0 32px 0 8px;
  border: 1px solid $borderColor;

  .ant-select-selection-search {
    left: 8px;
  }

  .ant-select-selection-item {
    line-height: $elementMainHeight;
  }

  .ant-select-selection-search-input {
    height: $elementMainHeight;
  }

  &:hover {
    border-color: $brandColor;
  }
}

.ant-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: calc(#{$elementMainHeight} - 2px);
  font-size: 15px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #F0F7FB;
}

.ant-select-dropdown-menu-item {
  background-color: white;

  &:not(:last-child) {
    border-bottom: 1px solid #DDDDDD;
  }

  &:hover {
    background-color: rgba(179, 179, 179, 0.38) !important;
  }
}

.ant-select-dropdown-menu-item-active {
  background-color: rgba(255, 255, 255, 0.38) !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}


.ant-calendar-date {
  transition: all 0.3s ease;

  &:hover {
    color: white;
    background-color: #FF0000;
  }
}


.ant-popover-content {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 8px;
    border: 1px solid #DDDDDD;

    .ant-popover-inner-content {
      padding: 0;

    }
  }
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: .2s;
}

.ant-tabs-tab-btn:focus, .ant-tabs-tab-btn:active {
  color: $brandColor;
}

.ant-pagination-options {
  display: none;
}

.ant-pagination-item-active {
  border-color: $brandColor;
  background-color: $brandColor;

  a {
    color: white;
    font-weight: 400;
  }

  & + .ant-pagination-item {
    border-left: 0;
  }
}

.ant-pagination-item {
  min-width: $elementMainHeight;
  height: $elementMainHeight;
  border-radius: 0;

  a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  &:nth-child(2) {
    border-radius: 2px 0 0 2px;
  }

  &:nth-last-child(3) {
    border-radius: 0 2px 2px 0;
  }

  &:not(:nth-last-child(3)) {
    border-right: 0;
  }
}

.ant-pagination-item:focus, .ant-pagination-item:hover {
  border-color: $brandColor;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: $brandColor;
}

.ant-pagination-item:focus a, .ant-pagination-item:hover a {
  color: white;
  font-weight: 400;
}

.ant-pagination {
  position: relative;

  .ant-pagination-prev {
    position: absolute;
    right: -60px;
    border-radius: 2px 0 0 2px;

    button {
      border-radius: 2px 0 0 2px;
      border-right-color: transparent;
    }
  }

  .ant-pagination-next {
    position: absolute;
    right: -100px;
    border-radius: 0 2px 2px 0;

    button {
      border-radius: 0 2px 2px 0;
    }

    @media (max-width: 1260px) {
      right: -96px;
    }
  }
}

.ant-pagination-item, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  margin-right: 0;
  min-width: $elementMainHeight;
  height: $elementMainHeight;
}

.ant-pagination-jump-prev, .ant-pagination-jump-next {
  border: 1px solid $borderColor;
  background-color: white;
  border-radius: 0;
  border-right: 0;
}

.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  padding: 12px 16px 4px 0;

  @media all and (max-width: 1000px){
    padding: 0;
  }
}

@media all and (max-width: 1000px) {
  .ant-pagination-next {
    border-radius: 0 2px 2px 0;
  }
}



