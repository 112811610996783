@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";

.cart-product-card-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  transition: all .2s ease-in-out;
  border-bottom: 1px solid $borderColor;
  padding: 16px;
  position: relative;

  &.disabled-card {
    background-color: #FAFAFA;
  }

  .image-wrapper {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: baseline;
    border-radius: 6px 0 0 6px;

    .image-proportion {
      padding-top: 100%;
      position: relative;
      width: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px 0 0 6px;
      }
    }
  }

  .product-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 96px);

    .title-box {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-title {
        font-size: 18px;
        line-height: 28px;
        font-family: Medium;
        height: 28px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: black;
      }

      .price-content {
        display: flex;
        align-items: flex-end;

        .price {
          display: flex;
          font-size: 18px;
          font-family: Bold;
          letter-spacing: .6px;

          &.new-price{
            color: $redColor;
          }
        }

        .old-price {
          display: flex;
          text-decoration: line-through;
          color: #a7a7a7;
          @include font(Regular, 15);
          margin-left: 12px;
        }
      }

      .not-available {
        color: $redColor;
        font-size: 12px;
      }
    }

    .delete-btn {
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        polygon {
          transition: .2s;
        }
      }

      &:hover {
        svg {
          polygon {
            fill: $redColor;
          }
        }
      }
    }

    .counter-box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .product-sum {
      color: $brandColor;
      @include font(Medium, 20);
      min-width: 130px;
      min-width: 130px;
      text-align: center;
    }

    .counter {
      border-radius: 4px;
      width: 140px;
      background-color: $greyBgColor;
      color: black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px;
      font-family: Medium;

      .down, .up {
        width: 36px;
        height: 36px;
        color: $brandColor;
        background-color: transparent;
        //border: 1px solid $borderColor;
        font-size: 30px;
        border-radius: 4px;
        @include flex-center;
      }
    }
  }

  &:hover {
    //box-shadow: 0 3px 6px 0 rgba(47, 51, 56, 0.16), inset 0 0 0 13px #fff;
    //transform: scale(1.01);
  }

  @media all and (max-width: 1300px) {

    .product-content {

      .price-content {
        .product-price {
          font-size: 22px;
        }

        .old-price {
          font-size: 17px;
        }
      }

      .title-box {
        width: 35%;
      }

      .product-title {
        font-size: 17px;
        line-height: 22px;
      }

      .price-content {

        .measurement {
          font-size: 16px;
        }

        .product-price {
          font-size: 20px;

          .price {
            span {
              font-size: 14px;
            }
          }

          .old-price {
            font-size: 13px;
          }
        }
      }

      .counter-box {

        .product-sum {
          font-size: 18px;
        }
      }
    }
  }

  @media all and (max-width: 1150px) {

    .product-content {

      .price-content {
        .product-price {
          font-size: 19px;
        }

        .old-price {
          font-size: 15px;
        }
      }

      .title-box {
        width: 35%;
      }

      .product-title {
        font-size: 16px;
        line-height: 20px;
        height: 40px;
      }

      .price-content {

        .measurement {
          font-size: 14px;
        }

        .product-price {
          font-size: 18px;

          .price {
            span {
              font-size: 13px;
            }
          }

          .old-price {
            font-size: 13px;
          }
        }
      }

      .counter {
        width: 120px;

        .down, .up {
          width: 30px;
          height: 30px;
        }
      }

      .counter-box {

        .product-sum {
          font-size: 16px;
        }
      }
    }
  }

  @media all and (max-width: 800px) {
    padding: 8px;
    .image-wrapper {
      width: 70px;
    }

    .product-content {
      width: calc(100% - 78px);

      .title-box {
        .product-title {
          width: 100%;
          font-size: 15px;
          height: 26px;
        }

        .price-content {
          margin-top: 5px;

          .price {
            font-size: 17px;
          }

          .old-price {
            @include font(Medium, 15);
          }
        }
      }

      .counter {
        width: 100px;

        .down, .up {
          width: 26px;
          height: 26px;

          svg {
            width: 12px;
          }
        }
      }
      .product-sum {
        font-size: 16px;
        min-width: 100px;
      }
    }
  }

  @media all and (max-width: 600px) {
    padding: 12px;

    .product-content {
      flex-wrap: wrap;
      position: relative;

      .title-box {
        width: calc(100% - 38px);
        margin-bottom: 8px;
        margin-right: 40px;

        .product-title {
          width: 100%;
        }
      }

      .price-content {
        margin-top: 0;
        width: 30%;

        .product-price {

          .price {
            span {
              font-size: 14px;
            }
          }

          .old-price {
            @include font(Medium, 14);
          }
        }
      }

      .counter-box {
        align-items: flex-end;

        .product-sum {
          font-size: 14px;
          width: 100px;
          text-align: center;
          margin-top: 6px;
        }
      }

      .delete-btn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  @media all and (max-width: 400px) {
    .image-wrapper {
      //width: 80px;
    }

    .product-content {
      //width: calc(100% - 80px);
      .price-content {
        //margin-left: -80px;
        .product-price {
          font-size: 14px;
        }
      }

      .counter-box {
        .product-sum {
          //font-size: 14px;
          //width: 100px;
        }
      }

      //.counter {
      //  width: 110px;
      //
      //  .down, .up {
      //    width: 26px;
      //    height: 26px;
      //
      //    svg {
      //      width: 12px;
      //    }
      //  }
      //}
    }
  }
}
