@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.blog-details-wrapper {
  min-height: calc(100vh - 80px);
  padding: 80px calc(2.5 * #{$mainPadding});
  position: relative;

  .image-wrapper {
    width: 70%;
    margin: auto;
    position: relative;

    .image-proportion {
      padding-bottom: 100%;
      position: relative;
      width: 100%;

      .social-wrapper{
        position: absolute;
        top: 0;
        left: -10px;
        transform: translateX(-100%);
        .shear-item{
          width: 40px;
          height: 40px;
          cursor: pointer;
          background-color: black!important;
          border-radius: 6px;
          @include flex-center;
          margin-bottom: 10px;
          position: relative;

          .copy-text {
            position: absolute;
            visibility: hidden;
            right: 50px;
            background-color: #424242;
            color: white;
            border-radius: 4px;
            padding: 6px 10px;
            z-index: 100;

            &:before {
              content: "";
              border-top: 9px solid #424242;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              position: absolute;
              top: 50%;
              right: -10px;
              transform: translateY(-50%) rotate(-90deg);
              z-index: 10;
            }
          }

          &:hover .copy-text {
            visibility: visible;
          }

          svg{
            width: 18px;
            height: 18px;
            path{
              fill: white!important;
            }
          }
        }
      }

      img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        height: 100%;
        z-index: 1;
        border-radius: 4px;
      }
    }
  }

  .date-box {
    width: 70%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #dddddd;
    margin: 30px  auto;

    .badge {
      color: $brandSecColor;
      font-size: 16px;
      font-family: Medium;
    }

    .date {
      color: #828282;
      font-size: 16px;
    }
  }

  .details-title {
    font-size: 52px;
    color: black;
    font-family: Medium;
    width: 70%;
    margin: auto;
    margin-bottom: 50px;
  }

  .details-description{
    width: 70%;
    margin: auto;
    font-size: 16px;
  }

  @media screen and (max-width: 1700px) {
    .details-title {
      font-size: 46px;
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 40px $mainPadding;

    .date-box {
      height: 60px;
      margin: 20px auto;

      .badge {
        font-size: 14px;
      }

      .date {
        font-size: 14px;
      }
    }

    .details-title {
      font-size: 30px;
      margin-bottom: 30px;
      line-height: 40px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 20px $mainPadding;

    .image-wrapper {
      width: 100%;
      .image-proportion{
        .social-wrapper{
          top: 10px;
          left: 10px;
          transform: unset;
          z-index: 10;
          .shear-item{
            width: 34px;
            height: 34px;
            margin-bottom: 6px;

            .copy-text {
              right: -70px;
              visibility: visible;
              &:before {
                content: "";
                border-top: 9px solid #424242;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                position: absolute;
                top: 50%;
                right: unset;
                left: -10px;
                transform: translateY(-50%) rotate(90deg);
                z-index: 10;
              }
            }

            svg{
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
    .date-box {
      width: 100%;
      margin-top: 0;
    }
    .details-title {
      width: 100%;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 0;
    }
    .details-description{
      width: 100%;
      padding: 16px 0;
    }
  }
}