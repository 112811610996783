@import '../../../assets/styles/mainVariables';
@import '../../../assets/styles/mixins';

.search_box {
  width: calc(100% - 62px);
  display: flex;
  position: relative;
  background-color: white;
  height: 46px;
  margin-right: 12px;

  .search-left-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  .clear-icon {
    position: absolute;
    right: 96px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    cursor: pointer;

    polygon {
      fill: #C3C3C3;
    }
  }

  .input-group-append {
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: center;
    background-color: $brandColor;

    .search_btn {
      border-right: none;
      padding: 0 22px;
      background-color: $brandColor;
      color: white;
      height: 100%;
      display: flex;
      align-items: center;
      @include font(Medium, 16);

      &.mobile {
        display: none;
      }
    }

  }

  .search {
    flex-grow: 1;
    font-size: 15px;
    padding-left: 40px;
    border: 2px solid $brandColor;
    border-right: 0;
    border-radius: 4px 0 0 4px;
    transition: .2s;

    &::placeholder {
      font-size: 14px;
      color: #C3C3C3;
    }

    &:focus-visible {
      outline: none;
    }

    &:focus {
      background-color: #f6f9f9;

      + .suggestions-wrapper {
        opacity: 1;
      }
    }
  }


  .suggestions-wrapper {
    position: absolute;
    margin-top: 5px;
    z-index: 5000;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 4px;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: all 0.2s;
    will-change: opacity;

    &.active {
      padding: 10px 0;

    }

    .item {
      width: 100%;
      padding: 10px 20px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      //border-bottom: 1px solid $borderColor;
      cursor: pointer;
      transition: all 0.2s;
      color: black;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;

      &:hover {
        color: $brandColor;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
