@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.notification-row {
  position: absolute;
  z-index: 1;
  top: 100%;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  overflow: hidden;
  will-change: opacity;
  &.open {
    opacity: 1;
    visibility: visible;
  }
  &.success{
    background-color: #21CF8F;
  }
  &.error{
    background-color: #FD345A;
  }
  &.info{
    background-color: #bcc8cf;
  }
  .inner-part {
    padding: 4px 0;
    color: white;
    text-align: center;
    font-size: 16px;
  }

  @media all and (max-width: 1000px){
    top: 60px;
  }
}
