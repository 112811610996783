@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";

.banner-card-wrapper {
  //height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //background-color: #F2F3F5;
  //padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  padding-bottom: 119%;

  .card-header {
    .card-header-title {
      color: black;
      @include font(Medium, 22);

      span {
        color: $brandSecColor;
      }
    }

    .card-date-counter {
      height: 22px;
      color: #FE0404;
      @include font(Medium, 14)
    }
  }

  .card-image {
    position: absolute;
    //margin: 16px auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //border-radius: 4px;
    object-fit: cover;
    z-index: -1;
  }

  .banner-info-wrapper {
    .banner-title {
      @include font(Medium, 19);
      height: 55px;
      overflow: hidden;
      color: black;
    }

    .price-wrapper {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .old-price {
        text-decoration: line-through;
        color: #a7a7a7;
        @include font(Regular, 18);
        position: relative;
        top: 6px;
      }

      .new-price {
        color: $blackTextColor;
        @include font(Bold, 28);
        position: relative;
        top: -2px;
      }

      .cart-btn {
        width: 46px;
        height: 46px;
        padding: 0;
        border-radius: 4px;
        background-color: $brandColor;
        @include flex-center;

        .title {
          display: flex;
        }

        &:disabled {
          color: #202020 !important;
          background: #d3d3d3 !important;
        }

        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }

  @media all and (max-width: 1500px) {
    //padding: 16px;
    .card-image {
      //width: 120px;
      //height: 120px;
    }

    .banner-info-wrapper {
      .banner-title {
        font-family: Medium;
        font-size: 18px;
        color: black;
        height: 52px;
        line-height: 26px;
      }
      .price-wrapper {
        margin-top: 8px;
        .new-price {
          font-size: 24px;
        }

        .cart-btn {
          width: 42px;
          height: 42px;
        }
      }
    }
  }
  @media all and (max-width: 1260px) {
    min-height: 320px;
    .card-header {
      .card-header-title {
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .card-image {

    }
  }
  @media all and (max-width: 460px) {
    .card-header {
      .card-header-title {
        margin-bottom: 8px;
        line-height: 24px;
      }
    }
  }
}
