@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.header-box {
  width: var(--contentWidth);
  margin: 0 auto;
  position: relative;

  .nav-bar-menu {
    width: 46px;
    height: 46px;
    border-radius: 4px;
    background-color: $brandColor;
    @include flex-center;
    margin-right: 16px;

    .menu-btn {
      $color: white;

      $height-icon: 18px;
      $width-line: 24px;
      $height-line: 3px;

      $transition-time: 0.2s;
      $rotation: 45deg;
      $translateY: calc(#{$height-icon} / 2);
      $translateX: 0;

      width: $width-line;
      height: $height-icon;
      position: relative;
      display: block;
      cursor: pointer;

      .line {
        display: block;
        background: $color;
        width: $width-line;
        height: $height-line;
        position: absolute;
        left: 0;
        border-radius: 4px;
        transition: all $transition-time;
        -webkit-transition: all $transition-time;
        -moz-transition: all $transition-time;

        &.line-1 {
          top: 0;
        }

        &.line-2 {
          top: 50%;
          transform: translateY(-50%);
        }

        &.line-3 {
          top: 100%;
          transform: translateY(-100%);
        }
      }

      &.active {
        .line-1 {
          transform: translateY($translateY) translateX($translateX) rotate($rotation);
          -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
          -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
        }

        .line-2 {
          opacity: 0;
        }

        .line-3 {
          transform: translateY(calc(#{$translateY} * -1) ) translateX($translateX) rotate($rotation * -1);
          -webkit-transform: translateY(calc(#{$translateY} * -1) ) translateX($translateX) rotate($rotation * -1);
          -moz-transform: translateY(calc(#{$translateY} * -1) ) translateX($translateX) rotate($rotation * -1);
        }
      }
    }
  }

  .content-wrapper {
    width: 100%;
    padding: 30px $mainPadding;
    display: flex;
    align-items: center;
    //justify-content: space-between;

    .header-left-part {
      display: flex;
      flex-grow: 1;
    }

    .header-center-wrapper {
      display: flex;
      justify-content: space-between;
      width: 65%;
      margin-left: 20px;

      .dropdown-menu-overlay {
        visibility: visible;
        transition: all .35s cubic-bezier(.39, .575, .565, 1);
        position: fixed;
        top: 106px;
        width: 100%;
        height: calc(100vh - 96px);
        left: 0;
        background: #000;
        z-index: 9000;
        opacity: .55;

      }

      .categories-list-dropdown {
        transition: all .35s cubic-bezier(.39, .575, .565, 1);
        position: absolute;
        background: white;
        z-index: 9001;
        top: 106px;
        left: 0;
        height: 600px;

        span {
          margin-right: 20px;
          color: white;
          white-space: nowrap;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          z-index: 10000;
          background-color: #0f182e;
          height: 40px;
          border-radius: 4px;
          padding-left: 10px;
          font-size: 13px;

          .arrow-icon {
            width: 14px;
            height: 14px;
            transform: rotate(90deg);
            margin: 0 10px;
          }

          @media all and (max-width: 1000px) {
            display: none;
          }
        }

        .dropdown-wrapper {
          height: 100%;

          .dropdown-content {
            border-radius: 4px;
            background-color: white;
            display: flex;
            height: 100%;

            .category-item {
              width: 100%;
              height: 45px;
              padding: 5px 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @include font(Medium, 14);
              border-right: 2px solid transparent;

              div {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .category-item-img {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 4px;
              }

              &.active{
                color: $brandColor;
                background-color: white;
                border-right-color: $brandColor;
              }
              &:hover {
                color: $brandColor;
                background-color: white;
                border-right-color: $brandColor;
              }
            }

            .categories-list, .categories-first-sub, .categories-second-sub {
              //border-radius: 4px;
              overflow-y: auto;
              @include custom-scrollbar-y;
              min-height: 100%;
              max-width: 350px;
              min-width: 280px;
              padding-bottom: 60px;
            }

            .categories-list {
              background-color: $greyBgColor;
              position: relative;
              top: -30px;
              height: calc(100% + 30px);
              display: flex;
              flex-direction: column;

              .categories-top-empty-wrapper {
                width: 100%;
                height: 30px;
                background-color: white;
              }
            }

            .categories-first-sub {
              .category-item:nth-child(odd) {
                height: 44px;
              }
              .category-item{
                &:hover {
                  border-right-color: transparent;
                }
              }
            }
          }
        }
      }

      .expend-on {
        visibility: hidden;
        opacity: 0;
      }
    }

    .menu-items-list {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin-bottom: 0;
      height: 46px;


      li {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
          margin: 0 16px;
        }

        &:last-child {
          margin-left: 16px;
        }

        .menu-item {
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          position: relative;
          color: $blackTextColor;
          list-style: none;
          @include font(Medium, 14);
          cursor: pointer;
          align-items: center;
          user-select: none;
          user-focus: none;
          white-space: nowrap;
          transition: .2s;

          svg {
            path {
              transition: .2s;
            }
          }

          &.active, &:hover {
            color: $brandColor;

            svg {
              path {
                fill: $brandColor;
              }
            }
          }

          .badge {
            position: absolute;
            color: white;
            right: 10px;
            width: 15px;
            height: 15px;
            top: -4px;
            border: 1px solid white;
            @include font(Medium, 10);
            background-color: #ff7800;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            //padding-left: 1px;
          }

          &.notifications-item{
            .badge {
              right: 20px;
              top: -4px;
            }
          }

          .plus {
            top: -5px;
            right: 8px;
            width: 18px;
            height: 18px;
          }

          .user-image {
            height: 24px;
            width: 24px;

            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .header-logo {
      margin-right: 16px;

      img {
        height: 46px;
      }
    }

    .profile-dropdown-wrapper {
      position: relative;
      transition: all 0.2s ease-in-out;

      .header-profile-wrapper {
        display: flex;

        .profile-img {
          width: 45px;
          height: 45px;
          border-radius: 4px;
          margin-right: 10px;
          object-fit: cover;
        }

        .header-profile-content {
          .username {
            //color: white;
            font-size: 19px;
          }

          .email {
            @include font(Bold, 12);
            color: #5A697E;
            letter-spacing: 0.4px;
          }
        }
      }

      .profile-dropdown-menu {
        position: absolute;
        background-color: white;
        //padding: 10px 0;
        z-index: 1;
        border-radius: 4px;
        width: 100%;
        visibility: hidden;
        box-shadow: 0 2px 4px 0 rgba(47, 51, 56, 0.16), inset 0 0 0 15px #fff;

        .menu-item {
          height: 38px;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 10px;

          &:first-child {
            border-radius: 4px 4px 0 0;
          }

          &:last-child {
            border-radius: 0 0 4px 4px;
          }

          &:hover {
            background-color: $greyColor;
          }

          &.with-badge {
            justify-content: space-between;

            .badge {
              width: 15px;
              height: 15px;
              font-size: 9px;
              background-color: $brandColor;
              color: white;
              font-family: Medium;
              border-radius: 50%;
              @include flex-center;
            }
          }
        }
      }

      &:hover {
        .profile-dropdown-menu {
          visibility: visible;
        }
      }
    }

    a {
      display: flex
    }

    .autorisation-btn {
      background-color: transparent;
      font-family: Medium;
    }

    .cart-icon-wrapper {
      position: relative;
      margin-right: 20px;
      display: flex;

      .cart-icon {
        cursor: pointer;
        width: 26px;
        height: 26px;

        path {
          fill: #5f5f6f;
        }
      }

      .badge {
        position: absolute;
        font-size: 14px;
        color: #5f5f6f;
        top: -8px;
        left: 14px;
      }

      .plus {
        left: 7px;
        font-size: 13px;
      }
    }

    @media all and (max-width: 1500px) {
      .left-side {
        .header-address-section {
          .shipping-address-btn {
            margin-left: 10px;
          }
        }
      }
    }

    @media all and (max-width: 1300px) {

      .header-profile-wrapper {

        .profile-img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }

        .header-profile-content {
          .username {
            font-size: 17px;
          }
        }
      }

    }
  }

  .mobile-nav-bar {
    display: none;
  }

  @media all and (max-width: 1000px) {
    position: fixed;
    z-index: 10000;
    //justify-content: flex-end;
    background-color: $brandColor;

    .content-wrapper {
      display: none;
    }

    .mobile-nav-bar {
      background-color: white;
      position: fixed;
      display: block;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 10000;
      padding: 0 12px 8px;

      .mobile-nav-top-side {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 46px;

        .header-logo{
            width: 86px;
            height: 32px;
          svg{
            height: 100%;
            width: 100%;
          }
        }

        .menu-item {
          display: flex;
          align-items: center;
          margin-left: 14px;

          &.notifications-item {
            position: relative;

            .badge {
              top: -4px;
              right: -2px;
            }
          }

          svg {
            width: 24px;
            height: 24px;
          }

          .user-image {
            height: 22px;
            width: 22px;

            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          }

          .badge {
            position: absolute;
            color: white;
            width: 15px;
            height: 15px;
            top: 6px;
            right: 7px;
            border: 1px solid white;
            @include font(Medium, 10);
            background-color: #ff7800;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            //padding-left: 1px;
          }
        }

        .action-part{
          display: flex;
        }
      }

      .mobile-nav-bottom-side {
        background-color: white;
        display: flex;
        padding-bottom: 0;

        .nav-bar-menu {
          margin-right: 8px;
          height: 42px;
          width: 42px;
        }

        .search_box {
          margin-right: 0;
          height: 42px;
          width: calc(100% - 50px);

          .input-group-append {
            transition: .2s;
          }
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .mobile-nav-bar {

      .mobile-nav-bottom-side {
        .search_box {
          .search-left-icon {
            display: none;
          }
          .clear-icon {
            right: 36px;
          }
          .search {
            border-color: $borderColor;
            border-width: 1px;
            padding-left: 12px;
          }
          .input-group-append {
            background-color: transparent;
            border: 1px solid $borderColor;
            border-left: 0;
            .search_btn {
              background-color: transparent;
              &.desktop {
                display: none;
              }
              &.mobile {
                display: flex;
                padding: 0 8px;
              }
            }
          }

          .search {
            &:focus + .suggestions-wrapper + .input-group-append {
              background-color: #f6f9f9;
            }
          }
        }
      }

      .right-side {

        .profile-img-content {

          .header-profile-wrapper {

            .header-profile-content {
              display: none;
            }
          }
        }
      }
    }
  }
}
