@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.ant-modal {
  position: relative;
  border-radius: 15px;
  transform-origin: center center !important;
  animation-duration: 0s !important;

  .info-modal-wrapper {
    min-height: 170px;
    max-height: 320px;
    overflow-y: auto;
    @include custom-scrollbar-y;

    .info-modal-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;

      .request-title {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 16px;
        font-family: Medium;
      }

      .request-description {
        font-size: 14px;
        margin-bottom: 20px;
      }

      .form-wrapper {
        display: flex;
        justify-content: space-between;

        .custom-input-group {

          &:first-child {
            width: 74%;
          }

          &:last-child {
            width: 22%;
          }
        }
      }
      .PrimaryButton {
        .tick-icon {
          width: 0;
          height: 14px;
          transition: all 0.2s;
          visibility: visible;

          path, polygon {
            fill: white;
          }
        }

        .show-tick {
          width: 22px;
          height: 22px;
          visibility: visible;
          opacity: 1;
          margin-left: 10px;
        }

        .hide-tick {
          width: 0;
          visibility: hidden;
          opacity: 0;
          //width: 0;
        }

        &:hover {
          box-shadow: 2px 0px 10px 0px rgba(113, 113, 113, 0.14);

          .tick-icon {
            path {
              fill: $brandColor;
            }
          }
        }
      }
    }
  }

  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      background-color: $brandColor;
      padding: 8px 20px;
      border-radius: 4px 4px 0 0;

      .modal-header-logo {
        height: 40px;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        line-height: 76px;
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }

  @media all and (max-width: 600px) {
    width: 94% !important;
    .ant-modal-body {
      padding: 16px;
    }
    .info-modal-wrapper {
      min-height: 250px;

      .info-modal-wrapper {
        .request-title {
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 14px;
        }

        .request-description {
          font-size: 14px;
          margin-bottom: 14px;
        }
      }
    }

    .ant-modal-content {

      .ant-modal-close {
        .ant-modal-close-x {
          line-height: 66px;

          .modal-close-icon {
            width: 18px;
            height: 18px;
          }
        }
      }

      .ant-modal-footer {
        display: none;
      }
    }
  }
}

