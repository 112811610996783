@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.market-details {

  .market-details-title, .market-details-description {
    @include font(Medium, 28);
    color: black;
  }

  .market-img {
    width: 100%;

    .image-wrapper {
      width: 100%;
      padding-top: 20%;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }

      @media all and (max-width: 600px) {
        img {
          border-radius: 6px;
        }
      }
    }
  }
  .rout {
    display: flex;
    flex-wrap: wrap;
    //width: 100%;
    //overflow: hidden;

    .rout-item {
      margin-right: 12px;
      margin-bottom: 12px;

      a {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #B1B1B3;
        transition: .3s;
        &:hover {
          color: $brandColor;
        }
      }

      svg {
        margin-left: 10px;
        width: 5px;
        path {
          fill: #797b7f;
        }
      }
    }
  }

  .details-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 1px solid $greyBorderColor;
    padding-bottom: 16px;

    .product-count {
      color: var(--brandSecColor);
      font-family: Medium;
      font-size: 14px;
    }
  }

  .market-details-cards {
    width: 100%;
  }

  .cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;

    .product-grid-card {
      width: calc(100% / 5);
    }
  }
  @media all and (max-width: 1260px) {
    .cards-wrapper {
      .product-grid-card {
        width: 25%;
      }
    }
  }
  @media all and (max-width: 1000px) {
    .market-img {
      width: 90%;
    }
    .cards-wrapper {
      .product-grid-card {
        width: calc(100% / 3);
      }
    }
  }
  @media all and (max-width: 700px) {
    .details-header {
      display: unset;
      .rout {
        .rout-item {
          margin-right: 6px;
          white-space: nowrap;
          a {
            font-size: 13px;
          }
          svg{
            margin-left: 6px;
          }
        }
      }
    }

    .cards-wrapper {
      width: 100%;
      .product-grid-card {
        width: 50%;
      }
    }
  }
  @media all and (max-width: 500px) {
    padding: 20px 0;
    .cards-wrapper {
      .product-grid-card {
        width: 100%;
      }
    }
  }
}