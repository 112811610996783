@import '../../../assets/styles/mainVariables';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';

.PrimaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $elementMainHeight;
  font-size: 15px;
  color: #fff;
  border-radius: 4px;
  padding: 0 35px;
  border: none;
  text-transform: capitalize;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: $brandColor;
  letter-spacing: 1px;
  white-space: nowrap;

  &:not(:disabled):hover {
    background-color: #085959 ;
  }
  &:not(.without-hover):hover {
    background-color: #085959 !important;
  }


  &.dark {
    color: #202020;
    background: #EFEFF0 !important;

    &:hover {
      background: $brandColor !important;
    }
  }

  &.bordered {
    color: #202020;
    background: inherit !important;
    border: 1px solid #202020;
  }

  &.hover-bg-change {
    &:hover {
      &:not(:disabled) {
        background-color: $brandColor !important;
        color: white;
      }
    }
  }

  &.bg-white {
    background-color: white;
    color: $brandColor;
    border: 1px solid $brandColor;
    &:hover {
      background-color: white !important;
    }
  }

  &.bg-grey {
    background-color: $greyBgColor;
    color: $blackTextColor;

    &:hover {
      background-color: #E7E8EA !important;
    }
  }

  &:disabled {
    color: #202020;
    cursor: not-allowed;
    background: #f5f5f5 !important;
  }

  .flex-row {
    display: flex;
    align-items: center;

    .ant-spin {
      margin-left: 8px;
    }
  }
}

.arrows {

  .next-btn, .prev-btn {
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    box-shadow: 0 1px 4px 0 rgba(10, 12, 11, 0.2), inset 0 0 0 15px #fff;
    @include flex-center;
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    svg {
      polygon {
        transition: .2s;
      }
    }

    &:not(.disabled):hover {
      svg {
        polygon {
          fill: $brandSecColor;
        }
      }
    }
    &.disabled {
      cursor: default;
    }
  }

  &.with-header {
    .next-btn, .prev-btn {
      top: calc(50% + 56px)
    }
  }

  .prev-btn {
    left: -20px;
  }

  .next-btn {
    right: -20px;

    .next-icon {
      transform: rotate(180deg);
    }
  }

  .disabled {
    display: none;
  }

  &.vertical {
    //position: relative;
    .next-btn, .prev-btn {
      border-radius: 2px;
      width: 24px;
      height: 24px;
      box-shadow: 0 1px 4px 0 rgba(10, 12, 11, 0.2), inset 0 0 0 15px #fff;
      @include flex-center;
      background-color: white;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      transition: .2s;
      visibility: visible;
      opacity: 1;

      svg {
        width: 14px;
        height: 14px;
      }
      &.disabled {
        visibility: hidden;
        opacity: 0;
      }

    }

    .prev-btn {
      top: 12px;
      transform: translateX(-50%) translateY(-50%);

      @media screen and (min-width: 1600px) {
        //top: 10px;
      }

      .prev-icon {
        transform: rotate(90deg);
      }
    }

    .next-btn {
      top: unset;
      right: unset;
      bottom: 20px;
      transform: translateX(-50%) translateY(50%);

      .next-icon {
        transform: rotate(-90deg);
      }
    }
  }

  @media all and (max-width: 1000px) {
    display: none;
  }
}

.product-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  height: 22px;
  border-radius: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 20px;
  @include flex-center;
  font-size: 12px;
  color: white;
  min-width: 70px;

  &:nth-child(1) {
    top: 10px;
  }

  @for $i from 1 through 10 {
    &:nth-child(n + #{$i}) {
      top: 10px + ($i - 1) * 30px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .arrows {

    .next-btn, .prev-btn {
      width: 40px;
      height: 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .PrimaryButton {
    font-size: 13px;
  }
  .arrows {
    .next-btn, .prev-btn {
      width: 30px;
      height: 30px;
    }

    .prev-btn {
      left: -15px;
    }

    .next-btn {
      right: -15px;
    }
  }
}

@media screen and (max-width: 600px) {
  .arrows {
    .next-btn, .prev-btn {
      width: 25px;
      height: 25px;

      svg, img {
        width: 16px;
      }
    }

    .prev-btn {
      left: -10px;
    }

    .next-btn {
      right: -10px;
    }

    .vertical {
      display: none;
      visibility: hidden;
    }
  }
}

@media screen and (max-width: 450px) {
  .PrimaryButton {
    width: 120px;
  }
}

