@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.user-details-wrapper {
  display: flex;
  position: relative;

  .left-side-menu {
    width: 25%;
    border: 1px solid $greyBorderColor;
    border-right: 0;
    border-top: 0;
    background-color: $greyBgColor;
    //height: fit-content;

    .left-side-menu-fixed {
      position: sticky;
      top: 106px;
      z-index: 2;
    }

    .left-side-menu-header {

      padding: 15px 30px;
      height: 80px;
      border-bottom: 1px solid $borderColor;
      border-top: 1px solid $borderColor;
      display: flex;

      .profile-img {
        width: 45px;
        height: 45px;
        border-radius: 4px;
        margin-right: 10px;
        object-fit: cover;
      }

      .menu-header-content {
        color: black;
        width: calc(100% - 50px);

        .header-name {
          font-size: 16px;
          font-family: Regular;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .header-email {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 13px;
        }
      }
    }

    .aside-menu-list {
      //border: 1px solid $borderColor;
      background-color: $greyBgColor;
      //border-top: none;
      //border-right: none;
      height: calc(100% - 80px);
      list-style: none;
      padding: 20px 0;

      li {
        cursor: pointer;
        font-size: 15px;
        height: 50px;
        transition: all .2s linear;
        position: relative;
        font-family: Medium;

        svg {
          margin-right: 10px;
          path {
            transition: all .2s linear;
          }
        }

        a {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 15px 30px;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
            border: none;
            width: 3.5px;
            background-color: transparent;
            transition: background-color .2s;
          }

        }

        .active {
          background-color: white;
          color: $brandColor;

          svg {
            path {
              fill: $brandColor;
            }
          }

          &:before {
            background-color: $brandColor !important;
          }
        }

        &:hover {
          background-color: white;
          color: $brandColor;

          svg {
            path {
              fill: $brandColor;
            }
          }
        }

        &.logout-wrapper {
          padding: 15px 30px;
          margin-top: 40px;
          display: flex;
          align-items: center;
        }
      }

      .menu-notification {
        //display: flex;
        //justify-content: space-between;
        //align-items: center;

        .badge {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 12px;
          background-color: $brandSecColor;
          width: 16px;
          height: 16px;
          color: white;
          border-radius: 50%;
          @include flex-center;
          font-size: 9px;
          font-family: Medium;
        }
      }
    }
  }

  .profile-content-wrapper {
    width: 75%;
    .profile-content-header {
      position: sticky;
      top: 106px;
      z-index: 2;
      background-color: white;
      padding: 15px 40px;
      border: 1px solid $borderColor;
      color: black;
      font-family: Medium;
      font-size: 20px;
      height: 80px;
      display: flex;
      align-items: center;

      .notification-details-header {
        display: flex;
        align-items: center;
        svg {
          margin-right: 12px;
          polygon {
            transition: .2s;
            fill: black;
          }

          &:hover {
            polygon {
              fill: $brandColor;
            }
          }
        }
      }
    }

    .content-wrapper {
      border: 1px solid $borderColor;
      border-top: 0;
      min-height: 644px;
    }
  }

  @media all and (max-width: 1200px) {
    .left-side-menu {
      width: 30%;

      .left-side-menu-header {
        .header-name {
          font-size: 17px;
        }
      }

      .aside-menu-list {
        li {
          font-size: 14px;
        }
      }
    }

    .profile-content-wrapper {
      width: 70%;
    }
  }

  @media all and (max-width: 1150px) {
    .profile-content-wrapper {
      .profile-content-header {
        padding: 15px 20px;
      }
    }
  }

  @media all and (max-width: 1000px) {

    margin-top: 96px;
    .left-side-menu {
      width: 260px;

      .left-side-menu-fixed {
        top: 96px;
      }

      .left-side-menu-header {
        height: 64px;
        padding: 8px 12px;

        .header-name {
          font-size: 16px;
        }
      }
      .aside-menu-list {
        li {
          a {
            padding: 15px 12px;
          }
          &.logout-wrapper {
            margin-top: 20px;
            padding: 15px 12px;
          }
        }
      }
    }

    .profile-content-wrapper {
      width: calc(100% - 250px);

      .profile-content-header {
        height: 64px;
        font-size: 18px;
        top: 96px;
      }
    }
  }

  @media all and (max-width: 850px) {
    flex-direction: column;
    .left-side-menu {
      width: 100%;
      padding-bottom: 0;
      height: unset;
      border-bottom: 0;

      .left-side-menu-header {
        padding: 10px;
        border-right: 1px solid $borderColor;

        .header-name {
          font-size: 16px;
        }
      }


      .aside-menu-list {
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        overflow-y: hidden;
        height: 50px;
        width: 100%;
        padding: 0;
        border-bottom: 0;
        border-right: 1px solid $borderColor;
        margin-bottom: 0;

        .menu-notification {
          .badge {
            top: 8px;
            transform: unset;
            right: -4px;
          }
        }

        &::-webkit-scrollbar {
          height: 0;
          //display: none !important;
        }

        li {
          font-size: 12px;
          //padding-left: 14px;
          white-space: nowrap;
          align-items: center;
          justify-content: center;

          &.logout-wrapper {
            margin-top: 0;
          }

          svg {
            width: 26px;
            height: 26px;
            margin: 0;
          }

          span {
            //display: none;
          }

          a {
            span {
              //display: none;
            }

            svg {
              width: 26px;
              height: 26px;
              margin-right: 8px;
            }

            &:before {
              content: "";
              position: absolute;
              height: 3.5px;
              top: unset;
              bottom: 0;
              right: 0;
              border: none;
              width: 100%;
              border-radius: 8px 8px 0 0;
              transition: background-color .2s;
            }
          }
        }
      }
    }

    .profile-content-wrapper {
      width: 100%;

      .settings-content {
        padding: 20px 10px;
      }
    }
  }

  @media all and (max-width: 750px) {
    .profile-content-wrapper {
      .profile-content-header {
        padding: 10px;
        margin-top: 0;
      }
    }
  }
}