@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.custom-page-wrapper {
  width: 100%;

  .custom-info-section {
    width: $contentWidth;
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: space-between;
    border-top: 0.5px solid white;

    .image-wrapper {
      width: 40%;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .custom-info-content {
      width: 54%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .custom-info-title {
        font-size: 36px;
        line-height: 50px;
        font-family: Medium;
        margin-bottom: 30px;
      }

      .custom-info-description {
        font-size: 18px;
        font-family: Regular;
        color: black;
        overflow: hidden;
        margin-bottom: 30px;
      }

      .PrimaryButton {
        width: 160px;
        margin-top: 30px;
      }
    }
  }

  @media all and (max-width: 1000px) {
    margin-top: 96px;

    .custom-info-section {
      flex-direction: column;
      padding: 4px 0;
      margin: 20px auto;

      .image-wrapper {
        width: 100%;
        margin-bottom: 50px;
      }

      .custom-info-content {
        width: 100%;
      }
    }
  }

  @media all and (max-width: 700px) {

    .custom-info-section {
      flex-direction: column;
      padding: $mainPadding 0;

      .image-wrapper {
        width: 100%;
        margin-bottom: 30px;
      }

      .custom-info-content {
        width: 100%;

        .custom-info-title {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 20px;
        }

        .custom-info-description {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }
  }
}