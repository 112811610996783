@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.filters-wrapper {

  .filters-title {
    background-color: white;
    padding: 11px 12px 12px;
    font-weight: 600;
    border-bottom: 1px solid $borderColor;
  }

  .ant-collapse {
    border: 0;
    border-radius: 0;
    //margin-bottom: 10px;
    background-color: white;
    .ant-collapse-item {
      border-bottom: 1px solid $borderColor;
      position: relative;
      //margin-bottom: 10px;

      .ant-collapse-header {
        background-color: white;
        padding: 12px;
        font-weight: 600;

        .ant-collapse-arrow {
          transition: all 0.2s;
          transform-origin: center;
          width: 20px;
          height: 8px;
          right: 12px;
          top: 20px;
          padding: 0;
          path {
            fill: black;
          }

          &.rotate {
            transform: rotate(90deg) !important;
          }
        }
      }

      .ant-collapse-content {
        border-top: 1px solid $borderColor;
        max-height: 300px;
        overflow-y: auto;

        .ant-collapse-content-box {
          padding-left: 12px;
          padding-right: 12px;

          .ant-checkbox-wrapper {
            display: flex;
            align-items: flex-start;

            .ant-checkbox {
              margin-right: 8px;
              margin-top: 3px;
            }
            .ant-checkbox-inner {
              border-color: $brandColor;
            }
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: $brandColor;
              }
            }

            &:hover {
              .ant-checkbox-checked::after {
                border-color: $brandColor;
              }
            }
          }

          .price-filter {
            .price-filter-inputs {
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: Bold;
              padding: 0 5px;

              > span {
                display: inline-block;
                width: 10px;
                border: 1px solid;
              }

              .ant-input-number {
                border-radius: 4px;
                border-color: $borderColor;
              }
            }

            .PrimaryButton {
              margin: 18px 0 4px;
              width: 100%;
              height: 40px;
              font-size: 15px;
            }
          }

          .ant-checkbox-group {
            display: flex;
            flex-direction: column;

            .ant-checkbox-wrapper {
              padding: 4px 0;
            }

            .ant-checkbox-group-item {
            }
          }
        }
      }

      &:first-child{
        border-radius: 4px 4px 0 0;
      }
      &:last-child{
        border-radius: 0 0 4px 4px;
      }
      &:hover{
        z-index: 10;
      }
    }

    .ant-collapse:first-child {
      .ant-collapse-item {
        .ant-collapse-content {
          overflow-y: hidden !important;
        }
      }
    }
  }

  @media all and (max-width: 900px) {
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-content {
          .ant-collapse-content-box {
            .price-filter {
              .price-filter {
                .price-filter-inputs {
                  .ant-input-number {
                    width: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-slider {
  margin: 10px 12px;

  .ant-slider-handle {
    border: none;
    background-color: $brandColor;
  }
}

.ant-slider:hover > .ant-slider-track {
  background-color: $brandColor;
}

.ant-slider-track {
  background-color: $brandColor !important;
}