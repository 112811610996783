@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.login-options-wrapper {
  display: flex;
  flex-direction: column;

  .social-sign-in-btn {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    letter-spacing: 0.6px;
    position: relative;
    font-size: 14px;
    padding: 0;
    margin: 0 auto 20px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .title {
      display: block;
      //position: absolute;
      //left: 50px;
      //top: 50%;
      //transform: translateY(-50%);
      font-family: Medium;

    }

    svg {
      margin-right: 10px;
      width: 16px;
      height: 100%;
      //position: absolute;
      //left: 20px;
      //top: 50%;
      //transform: translateY(-50%);
    }

    &.with-google {
      background-color: white;
      border: 1px solid #248CFF;
      margin-bottom: 20px;
      color: #248CFF
    }

    &.with-facebook {
      background-color: white;
      border: 1px solid #39579B;
      margin-bottom: 0;
      color: #39579B;

      svg g {
        fill: #39579B
      }
    }

    &.with-apple {
      //background-color: #e9e9e9;
      //color: $darkTextColor;
      background-color: black;
      color: white;

      svg {
        path {
          fill: white;
        }
      }
    }
  }


}
