@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.navigation-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  width: var(--contentWidth);
  margin: 0 auto;

  .left-side {
    .location-btn {
      display: flex;
      align-items: center;
      font-family: Medium;
      cursor: pointer;

      svg {
        margin: -4px 2px 0 -5px;
      }
    }
  }

  .right-side {
    .navigation-row {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin-bottom: 0;

      li {
        &:not(:last-child) {
          margin: 0 16px;
        }

        &:last-child {
          margin-left: 16px;
        }

        .ant-dropdown-trigger {
          padding-right: 0;
        }

        .menu-item {
          color: $blackTextColor;
          list-style: none;
          @include font(Medium, 16);
          cursor: pointer;
          align-items: center;
          user-select: none;
          user-focus: none;
          white-space: nowrap;
          transition: .2s;

          svg {
            margin-left: 6px;
            transition: .2s;
          }

          &.is-open {
            color: $brandColor;

            svg {
              transform: rotate(180deg);
              path {
                fill: $brandColor;
              }
            }
          }

          &.active, &:hover {
            color: $brandColor;

            svg {
              path {
                fill: $brandColor;
              }
            }
          }

          &.nav-dropdown{
            padding: 4px 16px 4px 0;
          }
        }
      }
    }
  }

  @media all and (max-width: 1000px) {
    display: none;
  }
}

.nav-menu-wrapper {
  width: 160px;
  box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.2);

  .ant-dropdown-menu-item {
    padding: 8px 18px;

    a {
      color: $blackTextColor;
      list-style: none;
      @include font(Medium, 16);
      cursor: pointer;
      align-items: center;
      user-select: none;
      user-focus: none;
      white-space: nowrap;
      transition: .2s;
    }

    &:hover {
      background-color: white;

      a {
        color: $brandColor;
        font-family: Medium;
      }
    }

    .ant-dropdown-menu-submenu-title {
      &:hover {
        //background-color: white;
        //color: $brandColor;
      }
    }
  }
}