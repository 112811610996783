@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.Homepage {
  width: 100%;
  height: 100%;
  position: relative;

  .SlidersWrapper {
    display: flex;
  }
  @media all and (max-width: 1260px) {
    .SlidersWrapper {
      flex-wrap: wrap;

      .slider-wrapper {
        width: 100%;
        margin-right: 0;
      }
    }
  }
  @media all and (max-width: 1000px) {
    .SlidersWrapper {
      margin-top: 96px;
    }
  }
}
