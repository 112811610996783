@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";
@import "../../assets/styles/mixins";

.confirm-password-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  //.inner-part {
  //  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  //  background-color: white;
  //  padding: 80px;
  //  display: flex;
  //  flex-direction: column;
  //  align-items: center;
  //  border-radius: 8px;
  //  margin: 60px 0;
  //  width: 100%;
  //
  //  h1 {
  //    font-family: Bold;
  //    color: black;
  //    font-size: 28px;
  //    text-align: center;
  //  }
  //
  //  .mail-icon {
  //    margin-bottom: 30px;
  //    width: 120px;
  //    height: 120px;
  //  }
  //
  //  .text {
  //    font-size: 18px;
  //    text-align: center;
  //    color: #282828;
  //    line-height: 36px;
  //
  //    span {
  //      font-family: Medium;
  //    }
  //  }
  //
  //  .email {
  //    font-size: 20px;
  //    color: black;
  //    font-family: Bold;
  //
  //  }
  //

  //  .passwords-wrapper {
  //    display: flex;
  //    flex-direction: column;
  //    align-items: center;
  //    justify-content: space-between;
  //    width: 100%;
  //
  //    .custom-input-group {
  //      width: 306px;
  //      input {
  //        margin-bottom: 0;
  //        height: 44px;
  //      }
  //      .visibility-icon {
  //        bottom: unset;
  //        top: 10px;
  //      }
  //    }
  //  }
  //
  //  .seconds {
  //    font-size: 22px;
  //    color: $brandColor;
  //    font-family: Medium;
  //    margin: 10px 0;
  //  }
  //  .code-container {
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //    margin: 20px 0;
  //
  //    .custom-input-group {
  //      margin: 0 4px;
  //    }
  //    .code {
  //      border-radius: 2px;
  //      font-size: 24px;
  //      height: 44px;
  //      width: 44px;
  //      margin-bottom: 0;
  //      border: 1px solid #eee;
  //      text-align: center;
  //      font-weight: 300;
  //      -moz-appearance: textfield;
  //      &:valid {
  //        border-color: $brandColor;
  //        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.1);
  //      }
  //      &:focus {
  //        outline: none;
  //        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  //      }
  //    }
  //
  //    .code::-webkit-outer-spin-button,
  //    .code::-webkit-inner-spin-button {
  //      -webkit-appearance: none;
  //      margin: 0;
  //    }
  //  }
  //
  //  .send-btn {
  //    background-color: $brandSecColor;
  //    width: 306px;
  //    height: 44px;
  //  }
  //}
  //@media (max-width: 800px) {
  //  .inner-part {
  //    padding: 60px 30px;
  //
  //    h1 {
  //      font-size: 22px;
  //      margin-bottom: 12px;
  //    }
  //    .text {
  //      font-size: 16px;
  //      line-height: 30px;
  //
  //      .email {
  //        font-size: 18px;
  //      }
  //    }
  //  }
  //}
  //@media (max-width: 450px) {
  //  .inner-part {
  //    padding: 30px 20px;
  //
  //    .mail-icon {
  //      margin-bottom: 20px;
  //      width: 80px;
  //      height: 80px;
  //    }
  //
  //    .text {
  //      line-height: 26px;
  //    }
  //
  //    .seconds {
  //      font-size: 18px;
  //      margin-top: 8px;
  //    }
  //    .code-container {
  //      margin: 8px 0 0;
  //
  //      .code {
  //        width: 36px;
  //        height: 36px;
  //      }
  //    }
  //    .passwords-wrapper {
  //
  //      .custom-input-group {
  //        width: 255px;
  //        margin-bottom: 16px;
  //        input {
  //          margin-bottom: 0;
  //          height: 36px;
  //        }
  //        .visibility-icon {
  //          bottom: unset;
  //          top: 10px;
  //        }
  //      }
  //    }
  //    .send-btn {
  //      width: 255px;
  //      height: 36px;
  //      font-size: 14px;
  //    }
  //  }
  //}
}
