@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.product-zoom-img {
  z-index: 999 !important;
  opacity: 1 !important;
  background-color: white;
}

.product-details-wrapper {
  width: 100%;
  border-radius: 4px;

  .product-details-content {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    padding: 0 0 0 12px;
    border: 1px solid $borderColor;

    .product-slider-wrapper {
      width: calc(42% - 20px);
      display: flex;
      justify-content: space-between;
      position: relative;
      align-items: center;
      //max-height: 500px;
      .arrows.vertical {
        visibility: hidden;
      }

      .pagination-img-wrapper {
        position: relative;
        width: 82px;
        height: 465px;
        padding: 10px 0;

        &:hover {
          .arrows.vertical {
            visibility: visible;
          }
        }

        &::-webkit-scrollbar {
          width: 0;
        }

        .slick-slider, .slick-list {
          height: 100%;
          //padding: 22px 0;
        }

        .pagination-item {
          width: calc(100% + 2px);
          padding-top: calc(100% - 2px);
          position: relative;
          cursor: pointer;
          border: 1px solid $borderColor;
          border-radius: 4px;

          &.active {
            border: 1px solid $brandColor;
          }

          .pagination-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
            z-index: 1;
          }

          .video-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 3px;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #0000003d;

          }

        }
      }

      .main-img-wrapper {
        width: calc(100% - 100px);
        padding: 20px 20px 20px 0;
        //overflow: hidden;

        &.product-default-img {
          position: relative;
          left: -54px;
        }

        .img-content {
          position: relative;
          width: 100%;
          padding-top: 83%;

          .product-badge {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            height: 30px;
            border-bottom-right-radius: 35px;
            padding-right: 30px;
            max-width: calc(100% - 40px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .badge-title {
              display: block;
              width: 100%;
              color: white;
              @include font(Medium, 12);
              padding-left: 15px;
              height: 100%;
              border-radius: 6px 0 0 0;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-top: 5px;
            }
          }

          .zoom-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        .product-current-img {
          //width: 100% !important;
          //height: 100% !important;
          //
          //img {
          //  object-fit: contain;
          //  width: 100% !important;
          //}
        }

        .image-proportion {
          position: relative;
          padding-bottom: 100%;

          .mobile-slider-img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100% !important;
            height: 100%;
            object-fit: cover;
            display: none;
          }

          .video-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }

          .zoom-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        .product-current-img {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .info-wrapper {
      width: 58%;
      border-left: 1px solid $borderColor;
      display: flex;
      flex-direction: column;

      .top-side {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        padding: 14px 16px;

        .code-wrapper {
          display: flex;
          align-items: center;
          font-family: Medium;

          span {
            color: $brandColor;
            margin-left: 4px;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .liked, .share, .stars-count {
            display: flex;
            align-items: center;
            font-family: Medium;
            cursor: pointer;
            margin-right: 30px;

            svg, img {
              width: 20px;
              height: 20px;
              margin-top: -3px;
              margin-right: 8px;
            }
          }

          .liked {
            margin-right: 0;
          }

          .share {
            position: relative;
          }

          .share-dropdown {
            position: absolute;
            z-index: 2;
            right: 10px;
            top: 24px;
            box-shadow: 0 1px 4px 0 rgba(10, 12, 11, 0.2), inset 0 0 0 15px #fff;
            padding: 8px;

            button {
              display: flex;
              align-items: center;
              width: 100%;
              background-color: white !important;
              font-size: 14px;
              padding: 4px !important;
              color: #464545 !important;
              white-space: nowrap;

              svg {
                margin-right: 8px;
                width: 16px;
                height: 16px;

                path {
                  fill: #464545;
                }
              }
            }

            .copy-wrapper {
              position: relative;
              background-color: white;

              .copy-text {
                position: absolute;
                visibility: hidden;
                right: 120px;
                background-color: #424242;
                color: white;
                border-radius: 4px;
                padding: 6px 10px;

                &:before {
                  content: "";
                  border-top: 9px solid #424242;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  position: absolute;
                  top: 50%;
                  right: -10px;
                  transform: translateY(-50%) rotate(-90deg);
                  z-index: 10;
                }
              }

              &:hover .copy-text {
                visibility: visible;
              }
            }
          }
        }
      }

      .info-main-content {
        padding: 14px 16px 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .product-available {
          margin-top: 4px;
          font-size: 16px;

          .available {
            color: #42CA27;
          }

          .not-available {
            color: $redColor;
          }
        }

        .product-title {
          color: black;
          @include font(Medium, 30);
          line-height: 36px;
        }

        .product-description {
          .description-title {
            @include font(Medium, 14);
            color: black;
            margin: 10px 0;
          }
        }

        .grey-wrapper {
          padding-bottom: 12px;

          .price-wrapper {
            position: relative;
            width: calc(100% + 32px);
            height: 130px;
            left: -16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            background-color: $greyBgColor;
            padding: 0 16px;
            margin-top: 30px;

            .left-part {
              margin-right: 24px;

              .prices {
                display: flex;
                align-items: center;

                .price {
                  color: $blackTextColor;
                  @include font(Bold, 24);
                  &.new-price{
                    color: $redColor;
                  }
                }

                .old-price {
                  text-decoration: line-through;
                  color: #a7a7a7;
                  @include font(Regular, 18);
                  margin-left: 12px;
                  position: relative;
                  top: 3px;

                  span {
                    max-width: 100px;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-right: 2px;
                    margin-right: 3px;
                    text-decoration: line-through;
                  }
                }

              }

              .delivery-info {
                display: flex;
                align-items: center;

                svg {
                  width: 16px;
                  height: 16px;
                }

                span {
                  position: relative;
                  margin-left: 8px;
                  @include font(Medium, 13);
                  top: 2px;
                  color: $brandColor;
                }
              }
            }
          }

          .basket-wrapper {
            display: flex;


            .plus-minus-wrapper {
              background-color: white;
              color: black;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 6px;
              width: 160px;
              border-radius: 4px;

              .down, .up {
                width: $elementMainHeight;
                height: $elementMainHeight;
                color: $brandColor;
                background-color: transparent;
                font-size: 30px;
                border-radius: 4px;
                @include flex-center;


                svg {
                  path, rect {
                    fill: $brandColor;
                  }
                }
              }
            }

            .basket-btn {
              &:disabled {
                color: white !important;
                cursor: not-allowed;
                background: #a6a6a6 !important;
              }
            }
          }

          .order-bottom-info {
            .order-bottom-info-title {
              @include font(Medium, 14);
              margin-top: 20px;
            }

            .order-bottom-info-texts {
              @include font(Regular, 14);
              display: flex;
              flex-wrap: wrap;

              .order-bottom-info-text-item {
                margin-top: 12px;

                span {
                  color: $brandSecColor;
                }

                &:first-child {
                  margin-right: 24px;
                }
              }
            }
          }
        }
      }

      .more-info-wrapper {
        display: flex;
        justify-content: space-between;
        color: $brandColor;
        margin-top: 20px;

        div {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          svg, img {
            width: 36px;
            height: 36px;
            margin-right: 10px;

            path {
              fill: $brandColor;
            }
          }

          &:nth-child(2) {
            svg {
              margin-left: 20px;
            }
          }
        }

        .link {
          color: $brandColor;
          margin-left: 3px;
          cursor: pointer;
        }
      }
    }
  }

  .attributes {
    padding: 30px 0;

    .attributes-title {
      font-size: 26px;
      font-family: Medium;
      color: black;
      letter-spacing: 1px;
      margin: 30px 0;
    }

    .attribute-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;

      &:nth-child(2n+1) {
        background-color: $greyBgColor;
      }

      .desc {
        color: black;
        //width: 120px;
        font-size: 16px;
        margin-right: 16px;
        white-space: nowrap;
      }

      .attribute-content {
        display: flex;
        align-items: center;
        transition: all 0.2s;
        flex-grow: 1;
        justify-content: flex-end;
        flex-wrap: wrap;

        .attribute-item {
          //width: 80px;
          height: 36px;
          border-radius: 4px;
          cursor: pointer;
          @include flex-center;
          color: $darkTextColor;
          font-size: 16px;
          white-space: nowrap;

          span {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .description-text {
    font-weight: 500;
    padding: 30px;
    margin-top: 30px;
    @include font(Regular, 15);
    background-color: $greyBgColor;
  }

  .special-offers {
    padding: 0 $mainPadding;
    margin-top: 20px;

    .special-offers-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .offer-title {
        font-size: 30px;
        font-family: Medium;
        color: black;
        letter-spacing: 1px;
      }
    }

    .special-offer-list-wrapper {
      width: 100%;
      overflow: hidden;
      //height: 162px;

      .slick-slider {
        .slick-list {
          .slick-track {
            //width: 100% !important;
            margin-left: unset;
          }
        }
      }
    }
  }

  .ant-tabs {
    margin: 30px 0 0 0;

    .ant-tabs-nav {
      margin: 0;

      .ant-tabs-nav-list {
        border-radius: 4px;
        overflow: hidden;
        //margin-bottom: 50px;

        .ant-tabs-tab {
          color: black;
          border: 2px solid $brandColor;
          display: flex;
          padding: 0 8px;
          align-items: center;
          justify-content: center;
          margin: 0;
          width: 200px;
          font-size: 16px;
          height: $elementMainHeight;
          @media all and (max-width: 800px) {
            font-size: 14px;
          }

          &:first-child {
            border-radius: 4px 0 0 4px;
          }

          &:nth-child(2) {
            position: relative;
            left: -2px;
          }

          &:nth-child(3) {
            margin-right: 0 !important;
            border-radius: 0 4px 4px 0;
            position: relative;
            left: -4px;
          }
        }

        .ant-tabs-tab-active {
          font-family: Bold;
          background-color: $brandColor;
          color: white;

          .ant-tabs-tab-btn {
            color: white;
            font-family: Regular;
          }

        }

        .ant-tabs-ink-bar {
          display: none;
        }
      }

    }

    .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: 0;
    }

    @media all and (min-width: 450px) {
      .ant-tabs-nav-operations {
        display: none !important;
      }
    }
  }

  @media all and (max-width: 1500px) {
    .product-details-content {
      .product-slider-wrapper {
        width: calc(50% - 20px);

        .pagination-img-wrapper {
          height: 465px;
        }
      }

      .info-wrapper {
        width: calc(50%);
      }
    }
  }
  @media all and (max-width: 1160px) {
    .product-details-content {
      .product-slider-wrapper {
        .pagination-img-wrapper {
          height: 378px;
        }
      }
    }
  }

  @media all and (max-width: 1000px) {
    .product-details-content {
      flex-direction: column;
      margin-top: 0;
      padding: 0;

      .product-slider-wrapper {
        width: 100%;
        padding: 16px;
        max-height: unset;

        .pagination-img-wrapper {
          height: 556px;
        }

        .arrows.vertical {
          visibility: visible;
        }

        .main-img-wrapper {
          padding: 0 0 0 16px;

          .product-current-img {
            display: none;
          }

          .image-proportion {
            .mobile-slider-img {
              display: block;
            }
          }
        }
      }

      .info-wrapper {
        width: 100%;
        border: 0;

        .top-side {
          border-top: 1px solid $borderColor;
        }

        .more-info {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }

    .special-offers {

      .special-offers-header {

        .offer-title {
          font-size: 20px;
        }
      }

      .special-offer-list-wrapper {
        width: 100%;
        overflow: hidden;
        //height: 162px;

        .slick-slider {
          .slick-list {
            .slick-track {
              //width: 100% !important;
            }
          }
        }

      }
    }
  }

  @media all and (max-width: 800px) {
    .product-details-content {
      .product-slider-wrapper {
        .pagination-img-wrapper {
          height: 465px;
        }
      }
    }
  }
  @media all and (max-width: 700px) {
    .product-details-content {
      .product-slider-wrapper {
        .pagination-img-wrapper {
          height: 378px;
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .description-text {
      padding: 10px;
    }
    .product-details-content {
      border: none;

      .product-slider-wrapper {
        flex-direction: column;
        padding: 0;

        .pagination-img-wrapper {
          height: unset;
          width: 100%;
          order: 1;

          .slick-slide {
            padding-right: 8px;
          }

          .arrows.vertical {
            display: none;
          }
        }

        .main-img-wrapper {
          padding-left: 0;
          width: 100%;

          &.product-default-img {
            position: relative;
            left: unset;
          }

          .mobile-slider-img {
            display: block;
          }
        }
      }

      .info-wrapper {

        .top-side {
          padding: 10px 0;
          border-top: none;

          .info-title {
            font-size: 18px;
          }

          .code-wrapper {
            margin-right: 20px;
            margin-top: 8px;
          }

          .actions {
            .stars-count, .code-wrapper, .share, .liked {
              margin-right: 20px;
              margin-top: 8px;
            }
          }
        }

        .info-main-content {
          padding: 16px 0 0;

          .grey-wrapper {
            .price-wrapper {
              left: -12px;
              width: calc(100% + 24px);
            }
          }
        }

        .product-title {
          font-size: 20px;
        }

        .attribute-wrapper {
          flex-wrap: wrap;

          .desc {
            font-size: 14px;
          }

          .attribute-content {
            display: flex;
            align-items: center;
            transition: all 0.2s;

            .attribute-item {
              height: 32px;
              font-size: 14px;

              span {
                margin-right: 5px;
              }
            }

            .current-attribute {
              border: 2px solid $brandColor;
              margin-right: 15px;


              .color-item {
                margin-right: 0;
              }
            }
          }
        }

        .price-wrapper {
          height: 110px;
          padding: 0 15px;

          .old-price {
            font-size: 16px;

            .discount {
              height: 17px;
              width: 30px;
              font-size: 11px;
            }
          }

          .description {
            font-size: 14px;
          }

          .sum {
            font-size: 24px;
            line-height: 32px;
          }

          .basket-wrapper {
            flex-grow: 1;
            justify-content: flex-end;
            margin-bottom: 8px;

            .basket-btn {
              height: 36px;
              width: 140px;
            }

            .plus-minus-wrapper {
              height: 36px;
              width: 140px !important;
            }
          }
        }

        .more-info-wrapper {
          flex-wrap: wrap;

          div {
            svg, img {
              width: 30px;
            }

            &:nth-child(2) {
              svg {
                margin-left: 0;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
    .attributes {
      padding-top: 0;
    }
  }

  @media all and (max-width: 430px) {
  .product-details-content {
    .info-wrapper {

      .top-side {
        .actions {
          flex-grow: 1;
          justify-content: space-between;
        }
      }
    }
  }
}

}
