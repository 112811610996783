@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.ant-modal {
  position: relative;
  border-radius: 15px;
  transform-origin: unset !important;
  animation-duration: 0s !important;
  user-select: none;

  .info-modal-wrapper {
    min-height: 170px;
    max-height: 280px;
    overflow-y: auto;
    @include custom-scrollbar-y;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-bg {
      position: absolute;
      top: -1px;
      right: 0;
      left: -1px;
      width: calc(100% + 1px);
    }

    img {
      margin-top: 30px;
    }

    .modal-title {
      font-size: 24px;
      font-weight: 600;
    }

    .modal-man-img {
      width: 160px;
      margin: 30px 0;
    }

    .broke-card {
      width: 180px;
    }

    .text-message {
      @include font(Regular, 14);
      margin-top: 30px;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;

      .PrimaryButton {
        &.cancel-btn {
          margin-right: 12px;
        }

        //width: calc(50% - 8px);
        height: $elementMainHeight;
        margin-top: 30px;
        font-size: 14px;
      }
    }

    &.modal-qr-wrapper {
      max-height: unset;

      .qr-wrapper {
        width: 100%;
        text-align: center;

        .qr-img {
          width: 80%;
          height: 100%;
          overflow: hidden;
        }
      }
    }
  }

  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      background-color: $brandColor;
      padding: 8px 20px;
      border-radius: 4px 4px 0 0;

      .modal-header-logo {
        height: 40px;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        line-height: 76px;
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }

  @media all and (max-width: 600px) {

    .PrimaryButton {
      width: unset;
    }
    width: 94% !important;

    .ant-modal-body {
      padding: 16px;
    }
    .info-modal-wrapper {
      min-height: 250px;

      .modal-logo {
        width: 150px;
        height: 30px;
      }

      .modal-man-img {
        width: 140px;
        height: 200px;
        margin-top: 20px;
      }

      .broke-card {
        width: 160px;
      }

      .text-message {
        width: 100%;
        font-size: 12px;
        margin-top: 20px;
      }

      .link-button {
        height: 35px;
        margin-top: 20px;
        font-size: 12px;
        width: unset;
      }

      &.modal-qr-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .qr-wrapper {
          .qr-img {
            width: 90%;
          }
        }
      }
    }

    .ant-modal-content {

      .ant-modal-close {
        .ant-modal-close-x {
          line-height: 66px;

          .modal-close-icon {
            width: 18px;
            height: 18px;
          }
        }
      }

      .ant-modal-footer {
        display: none;
      }
    }
  }
}

